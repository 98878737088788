import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Pin } from '../assets/images/pin.svg';

export const Marker = (props: any) => {
  const { color, name, id, onMarkerClick } = props;
  return (
    <Link to={`/ads/${id}`} onClick={onMarkerClick}>
      <Pin
        style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
      />
    </Link>
  );
};
