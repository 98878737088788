import {
  BackButton,
  Button,
  HamburgerButton,
  Section,
  TextField,
  TopNavbar,
  useLogout,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { changedPassword } from '../api';
import { HambergerMenu, LinkadTopNavBar } from '../components';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const logout = useLogout();

  const [ChangedPassword] = useMutation(changedPassword, {
    variables: {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
    },
    onCompleted: () => {
      alert('비밀번호 변경이 완료되었습니다. 재로그인 해주세요.');
      logout();
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  return (
    <>
      <div className="sm:hidden">
        <TopNavbar
          title="비밀번호 변경"
          right={<HamburgerButton onClick={() => setOpen(true)} />}
          left={<LogoIcon className="h-8 w-28" />}
        />
      </div>
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div className="hidden sm:block inset-0">
        <LinkadTopNavBar />
        <div className=" text-2xl mt-24 text-center">비밀번호 변경</div>
      </div>
      <Section>
        <TextField
          label="*기존 비밀번호"
          placeholder="비밀번호를 입력해주세요."
          value={oldPassword}
          type="password"
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <div></div>
        <TextField
          label="*새로운 비밀번호"
          placeholder="비밀번호를 입력해주세요."
          value={newPassword}
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="*새로운 비밀번호 확인"
          placeholder="비밀번호를 재입력해주세요."
          value={newPasswordConfirmation}
          type="password"
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
        />
        <div></div>
        <Button
          text="비밀번호 변경"
          style={{ marginTop: 20 }}
          disabled={
            !oldPassword ||
            !newPassword ||
            !newPasswordConfirmation ||
            newPassword !== newPasswordConfirmation
          }
          onClick={() => ChangedPassword()}
        ></Button>
      </Section>
    </>
  );
};

export default ChangePassword;
