import { Category } from './Category';
import { Company } from './Company';

export interface User {
  id: number;
  email: string;
  name: string;
  phone: string;
  organization?: string;
  role: Role;
  categoryId?: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  category?: Category;
  company?: Company;
  securePhone?: string;
}

export enum Role {
  ADMIN = 'ADMIN',
  BUSINESS = 'BUSINESS',
  USER = 'USER',
}
