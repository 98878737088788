import { Button, Chip } from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { Ad } from '../types/Ad';

interface InquiryModalProps {
  setClose: () => void;
  ad: Ad;
}

const InquiryModal: FC<InquiryModalProps> = ({ setClose, ad }) => {
  return (
    <div className="w-80 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/3  inset-x-0 p-2 transition transform origin-top-right">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center w-full mb-4 justify-between">
            <div className="w-full text-center ml-13 font-bold text-xl">
              연락처 보기
            </div>
            <div className="mr-2">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 "
                onClick={() => setClose()}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <hr className="mb-5" />
          <div className="w-full">
            <div className="font-bold mb-3 text-lg">{ad.company.name}</div>
            <div className="flex mb-2">
              <div className="w-1/3 text-gray-500 flex-shrink-0">매물 번호</div>
              <div className="w-2/3 text-gray-900">{ad.id}</div>
            </div>
            <div className="flex mb-2">
              <div className="w-1/3 text-gray-500 flex-shrink-0">광고 형태</div>
              <div className="flex flex-wrap w-2/3">
                {ad.tags.map((tag) => (
                  <Chip
                    selected={true}
                    text={tag.name}
                    style={{
                      cursor: 'default',
                      marginBottom: '4px',
                      marginRight: '4px',
                    }}
                  />
                  // <div className="text-gray-900 mr-3">{tag.name}</div>
                ))}
              </div>
            </div>
            <div className="flex mb-2">
              <div className="w-1/3 text-gray-500 flex-shrink-0">제목</div>
              <div className="w-2/3 text-gray-900">{ad.title}</div>
            </div>
            <div className="flex mb-2">
              <div className="w-1/3 text-gray-500m flex-shrink-0">전화번호</div>
              <div className="w-2/3 text-gray-900">
                {ad.user?.securePhone
                  ? ad.user?.securePhone.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      '$1-$2-$3',
                    )
                  : ad.user?.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
              </div>
            </div>

            <div className="w-full mt-6 text-brand-1 ">
              <a href={`tel:${ad.user?.phone}`}>
                <Button style={{ width: '100%' }} text="전화하기" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryModal;
