import {
  Button,
  LoadingScreen,
  PhoneNumberField,
  Section,
  Select,
  TextField,
  useMutation,
  useQuery,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CATEGORIES,
  CategoriesData,
  CreatePhone,
  UpDatePhoneVerify,
  updateUser,
} from '../api/queries';
import { meState } from '../store';

const MyPageProfile = () => {
  const me = useRecoilValue(meState);
  const [phone, setPhone] = useState(me?.phone);
  const [organization, setorganization] = useState('');
  const [categoryId, setCategoryId] = useState(me?.category?.id);
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [code, setCode] = useState('');
  const [phoneId, setPhoneId] = useState('');

  const { loading, error, data } = useQuery<CategoriesData>(CATEGORIES);

  const [UPDATEUSER] = useMutation(updateUser, {
    variables: {
      phone: phone || me?.phone,
      categoryId: categoryId || me?.category?.id,
      organization: organization || me?.organization,
    },
  });

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setPhoneId(createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const handleSubmit = () => {
    if (authenticationComplet) {
      UPDATEUSER();
      alert('수정이 완료 되었습니다.');
      window.location.reload();
    }

    UPDATEUSER();
    alert('수정이 완료 되었습니다.');
    window.location.reload();
  };

  const history = useHistory();

  if (!me) return <LoadingScreen />;
  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <div className="w-full p-4">
        <div className="text-xl truncate text-gray-800 font-bold my-1">
          내 프로필
        </div>
        <div className="text-base text-gray-500">
          지원 결과 혹은 추천 알바 정보를 받아볼 이메일, 연락처 정보를
          입력해주세요.
        </div>
      </div>
      <Section>
        <TextField
          label="이름"
          placeholder="이름을 입력해주세요."
          value={me.name}
          disabled
        />
        <TextField label="*이메일" value={me?.email || ''} disabled />
        <PhoneNumberField
          label="*핸드폰 번호"
          value={phone || me?.phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={authenticationComplet}
        />
        {authenticationNumber ? (
          <>
            {!authenticationComplet ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() =>
                    phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
                  }
                >
                  인증번호 재전송
                </Button>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 mt-1">
                    <input
                      type="text"
                      name=""
                      className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                      placeholder="인증번호를 입력해주세요."
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button
                      className="col-span-1 bg-brand-1 rounded-md text-white"
                      onClick={() =>
                        code
                          ? upDatePhoneVerify()
                          : alert('인증번호를 입력해주세요.')
                      }
                    >
                      확인
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {authenticationComplet && <div>* 인증이 완료되었습니다.</div>}
              </>
            )}
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() =>
              phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
            }
          >
            인증번호 보내기
          </Button>
        )}

        <Select
          label="*업종"
          placeholder="업종을 선택해주세요"
          value={categoryId || me?.category?.id}
          onChange={(e) => setCategoryId(Number(e.target.value))}
        >
          {data?.categories.map((category) => (
            <option value={category.id}>{category.title}</option>
          ))}
        </Select>
        <TextField
          label="*상호명"
          placeholder="상호명을 입력해주세요."
          value={organization || me?.organization}
          onChange={(e) => setorganization(e.target.value)}
        />
        <Button
          variant="outlined"
          onClick={() => {
            history.push('/changepw');
          }}
        >
          비밀번호 변경
        </Button>
        <Button onClick={handleSubmit}>수정 완료</Button>
      </Section>
    </>
  );
};

export default MyPageProfile;
