import { LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React from 'react';
import { TAGS, TAGSData } from '../api';
import { FilteringPageContent } from '../components';

export const FilteringPage = () => {
  const { loading, error, data } = useQuery<TAGSData>(TAGS);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <div>
      <FilteringPageContent tags={data.tags} />
    </div>
  );
};
