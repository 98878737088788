import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  TopNavbar,
  HamburgerButton,
} from '@entropyparadox/reusable-react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { HambergerMenu, LinkadTopNavBar } from '../components';
import MyPageProfile from './MyPageProfile';
import MyPageLikeList from './MyPageLikeList';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';

export const MyPage: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="sm:hidden">
        <TopNavbar
          title="마이페이지"
          right={<HamburgerButton onClick={() => setOpen(true)} />}
          left={<LogoIcon className="h-8 w-28" />}
        />
      </div>
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div className="hidden sm:block inset-0">
        <LinkadTopNavBar />
        <div className=" text-2xl mt-24 text-center">마이페이지</div>
      </div>

      <div className=" w-full bg-white  flex justify-center py-4 max-w-md mx-auto">
        <div className="w-full pt-4">
          <Tabs padding="px-3">
            <Tab
              text="찜한 매물"
              active={pathname === '/mypage'}
              onClick={() => {
                history.push('/mypage');
              }}
            />
            <Tab
              text="프로필"
              active={pathname === '/mypage/edit'}
              onClick={() => {
                history.push('/mypage/edit');
              }}
            />
          </Tabs>
          <Switch>
            <Route path="/mypage/" exact component={MyPageLikeList} />
            <Route path="/mypage/edit" component={MyPageProfile} />
          </Switch>
        </div>
      </div>
    </>
  );
};
