import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
  BottomFixedButton,
  useQuery,
  useQueryParams,
} from '@entropyparadox/reusable-react';
import { LinkadTopNavBar, GoogleMap } from '../components';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ADListPage } from './ADListPage';
import { ADDetailPage } from './ADDetailPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { adsByBoundsAndTags, ADSData } from '../api';
import { FilteringPage } from './FilteringPage';
import { CompanyDetailPage } from './CompanyDetailPage';
import './index.css';
//@ts-ignore
import { Helmet } from 'react-helmet';
import NoticePopUp from '../components/NoticePopUP';

interface PathParamsProps {
  id: string;
}

const HomePage: React.FC<RouteComponentProps<PathParamsProps>> = ({
  location,
}) => {
  const { ids } = useQueryParams();
  const [isMapView, setMapView] = useState(true);
  const [mapViewHeight, setMapViewHeight] = useState('90vh');
  const { pathname } = useLocation();
  const [mapdata, setMapdata] = useState(undefined);
  const [close, setClose] = useState(true);

  if (mapdata === undefined) {
    const savedMapdata = localStorage.getItem('mapdata');
    console.log('savedMapdata', savedMapdata);
    if (savedMapdata && savedMapdata !== 'undefined') {
      setMapdata(JSON.parse(savedMapdata));
    }
  }

  useLayoutEffect(() => {
    updateMapViewSize();
  }, [updateMapViewSize]);

  useEffect(() => {
    if (pathname === '/') {
      const script = document.createElement('script');
      script.src = '/channelio.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [pathname]);

  const { loading, error, data } = useQuery<ADSData>(adsByBoundsAndTags, {
    variables: { tagIds: ids ? ids : 0, bounds: JSON.stringify(mapdata) ?? '' },
  });

  if (loading) return <></>;
  if (error || !data) return <></>;

  function updateMapViewSize() {
    const el = document.getElementById('mapViewContainer');
    if (el) {
      const height = `${el.clientHeight - 72 || 0}px`;
      setMapViewHeight(height);
    }
  }

  const clientWidth = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };

  const isDesktop = () => {
    return clientWidth() >= 768;
  };

  function callbackBound(res: any) {
    console.log('callback res', res);
    if (res.bounds.nw && res.bounds.se) {
      setMapdata(res);
      localStorage.setItem('mapdata', JSON.stringify(res));
    }
  }
  console.log(data);

  function renderMapView() {
    return (
      <GoogleMap
        places={data?.adsByBoundAndTag ?? []}
        mapdata={mapdata}
        callback={callbackBound}
        height={mapViewHeight}
      />
    );
  }

  function renderDesktop() {
    if (!data) return;
    return (
      <>
        <LinkadTopNavBar />
        <div
          id="mapViewContainer"
          className="absolute inset-y-0 left-0 w-screen-112 mt-16 overflow-hidden"
        >
          {renderMapView()}
        </div>
        <section className="absolute inset-y-0 right-0 max-w-full flex">
          <div className="mt-16 w-112">
            <div
              className={`h-full flex flex-col shadow-xl overflow-y-scroll scroll-box`}
            >
              <div className={`bg-white h-screen z-10 scroll-box`}>
                <Switch>
                  <Route path="/companies/:id" component={CompanyDetailPage} />
                  <Route path="/filter" component={FilteringPage} />
                  <Route path="/ads/:id" component={ADDetailPage} />
                  <Route
                    path="/"
                    component={() => (
                      <ADListPage
                        setMapView={(b: boolean) => setMapView(b)}
                        places={data.adsByBoundAndTag}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  function renderMobile() {
    if (!data?.adsByBoundAndTag) return;
    return (
      <>
        <LinkadTopNavBar />
        <div className={`absolute mt-11 inset-0 w-full`}>
          <div
            id="mapViewContainer"
            className={`absolute inset-y-0 left-0 w-full overflow-hidden ${
              isMapView === true && pathname === '/' ? 'block z-10' : 'hidden'
            }`}
          >
            {renderMapView()}
            <BottomFixedButton
              reversed={true}
              onClick={() => setMapView(false)}
              style={{ zIndex: 100 }}
            >
              이 지역 매물보기
            </BottomFixedButton>
          </div>
          <div
            className={`${
              isMapView === true && pathname === '/' ? 'hidden' : 'block z-10'
            }`}
          >
            <div
              className={`h-full flex flex-col shadow-xl overflow-y-scroll scroll-box`}
            >
              <div className={`bg-white h-screen scroll-box`}>
                <Switch>
                  <Route path="/companies/:id" component={CompanyDetailPage} />
                  <Route path="/filter" component={FilteringPage} />
                  <Route path="/ads/:id" component={ADDetailPage} />
                  <Route
                    path="/"
                    component={() => (
                      <ADListPage
                        setMapView={(b: boolean) => setMapView(b)}
                        places={data.adsByBoundAndTag}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {close === true && (
        <NoticePopUp
          setClose={() => {
            setClose(false);
          }}
        />
      )}
      <Helmet
        title="Link Ad"
        meta={[
          {
            name: 'description',
            content:
              '공평하고 투명한 광고시장을 꿈구는 옥외광고 플랫폼 링크애드 입니다.',
          },
          { property: 'og:type', content: 'website' },
          { property: 'og:title', content: 'Link Ad' },
          {
            property: 'og:description',
            content:
              '공평하고 투명한 광고시장을 꿈구는 옥외광고 플랫폼 링크애드 입니다.',
          },
          {
            property: 'og:image',
            content:
              'https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Ff27c39b9-ccf1-4001-a163-a6bbe28ba707%2Fcover.png?table=block&id=a8ac5178-cdf8-4ef6-bec7-9fc64a0d3ae2&width=3440&userId=d404037b-41b1-4729-9600-e46ba72f7b9a&cache=v2',
          },
          { property: 'og:url', content: 'http://localhost:3000' },
        ]}
      />
      {isDesktop() ? <>{renderDesktop()}</> : <>{renderMobile()}</>}
    </>
  );
};

export default withRouter(HomePage);
