import React, { useState } from 'react';
import {
  BackButton,
  Divider,
  HamburgerButton,
  List,
  LoadingScreen,
  TopNavbar,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import { ADItem, HambergerMenu } from '../components';
import { companiesAds, CompaniesAdsData } from '../api';

export const CompanyDetailPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const id = useIdParam();

  const { loading, error, data } = useQuery<CompaniesAdsData>(companiesAds, {
    variables: {
      id: Number(id),
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <TopNavbar left={<BackButton />} title="회사 정보" />
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div className="flex justify-center items-center text-center py-8">
        <div>
          <div className="text-lg font-bold text-gray-800">
            {data.companiesAds[0].company.name}
          </div>
          <div className="text-gray-500 mt-2">
            {data.companiesAds[0].company.address}{' '}
            {data.companiesAds[0].company.detailAddress}
          </div>
          <div className="text-gray-500 mt-2">
            {data.companiesAds[0].company.homepage}
          </div>
        </div>
      </div>
      <Divider height="h-3" />
      <div className="w-full p-6">
        <div className="text-lg font-bold text-gray-700">
          광고사의 다른 매물
        </div>
      </div>
      {data.companiesAds.map((ad) => (
        <List>
          <ADItem ad={ad} />
        </List>
      ))}
    </>
  );
};
