import React from 'react';
import {
  TopNavbar,
  BackButton,
  useQuery,
  useIdParam,
  LoadingScreen,
} from '@entropyparadox/reusable-react';
import { ADDetailPageContent } from '../components/ADDetailPageContent';
import { AD, ADData } from '../api';
//@ts-ignore
import { Helmet } from 'react-helmet';

export const ADDetailPage: React.FC = () => {
  const id = useIdParam();
  const { loading, error, data } = useQuery<ADData>(AD, {
    variables: {
      id: id,
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Link Ad :: {data.ad.title}</title>
        <meta name="description" content={data.ad.description} />
        <meta property="og:title" content={data.ad.title} />
        <meta property="og:description" content={data.ad.description} />
        <meta property="og:image" content={data.ad.thumbnail} />
        <meta property="og:type" content="website" />
      </Helmet>
      <TopNavbar left={<BackButton />} title="상세 정보" />
      <ADDetailPageContent ad={data.ad} />
    </>
  );
};
