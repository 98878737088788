import {
  AspectRatioImage,
  Button,
  Chip,
  Divider,
  LoadingScreen,
  Section,
  useIdParam,
  useMutation,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HorizontalADItem } from '.';
import { ReactComponent as VertMenu } from '../assets/images/menu-vertical.svg';
import { Ad } from '../types/Ad';
import {
  companiesAds,
  CompaniesAdsData,
  DeletedAd,
  getStatistics,
  StatisticsData,
  StatisticsResponse,
} from '../api/queries';
import { meState } from '../store';
import AddLikeIcon from './AddLikeIcon';
import ModalPopUp from './ModalPopUp';
import InquiryModal from './InquiryModal';
import { ReactComponent as HeartIcon } from '../assets/images/heart-icon.svg';
import { ReactComponent as Alarm } from '../assets/images/alarm.svg';
import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
import { Role } from '../types/User';

interface ADStatisticsProps {
  codeDNG: number;
}

export const ADStatistics: FC<ADStatisticsProps> = ({ codeDNG }) => {
  const history = useHistory();

  const { loading, error, data } = useQuery<StatisticsResponse>(getStatistics, {
    variables: {
      codeDNG,
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;
  console.log(data);

  return (
    <Section>
      <div className="text-lg font-bold text-gray-700 mb-2">데이터 분석</div>
      <div className="flex justify-between">
        <h2 className="font-semibold">{data?.statistics?.dong} 인구현황</h2>
        <span className="text-sm text-gray-500">
          업데이트 {data?.statistics?.date}
        </span>
      </div>
      <div className=" text-center space-x-2 w-full py-4 bg-gray-500 rounded-lg text-white">
        <span className="block">전체 생활인구 수</span>
        <span className="text-3xl font-semibold">
          {data?.statistics?.total.toLocaleString()}명
        </span>
      </div>
      {/* <div className="flex space-x-4 pb-6">
        <div className="flex pl-4 flex-col w-full py-4 bg-blue-500 rounded-lg text-white">
          <span>남자 인구 수</span>
          <span className="text-3xl font-semibold ml-0"> 12,574명 </span>
        </div>
        <div className="flex pl-4 flex-col w-full py-4 bg-red-400 rounded-lg text-white">
          <span>여자 인구 수</span>
          <span className="text-3xl font-semibold ml-0"> 14,681명 </span>
        </div>
      </div> */}
      <BarChart statistics={data?.statistics} />
      {/* <div className="pb-6"></div>
      <LineChart /> */}
      <span>서울시 생활인구 데이터를 분석해서 생성한 정보입니다.</span>
    </Section>
  );
};
