import React from 'react';
import { AspectRatioImage } from '@entropyparadox/reusable-react';
import { Link } from 'react-router-dom';
import { Ad } from '../types/Ad';

interface HorizontalADItemProps {
  ad: Ad;
}

export const HorizontalADItem: React.FC<HorizontalADItemProps> = ({ ad }) => {
  return (
    <Link to={`/ads/${ad.id}`}>
      <div className="md:w-48 w-56">
        <AspectRatioImage
          src={ad.thumbnail || ''}
          alt=""
          pb="pb-3/5"
          rounded="rounded"
        />
        <div className="flex flex-wrap mt-2">
          {ad.tags.map((tag) => (
            <div key={tag.id} className="text-sm text-gray-500 mr-2">
              {tag.name}
            </div>
          ))}
        </div>
        <div className="truncate text-gray-800 font-bold mt-1">{ad.title}</div>
        <div className="text-gray-700 mt-1">
          {String(ad.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </div>
      </div>
    </Link>
  );
};
