import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from 'react-chartjs-2';

interface BarChartProps {
  statistics: any;
}

export const BarChart: FC<BarChartProps> = ({ statistics }) => {
  if (!statistics) return <></>;
  const maleData = Object.values(statistics.male).filter((v) =>
    typeof v === 'number' ? v : null,
  );
  const femaleData = Object.values(statistics.female).filter((v) =>
    typeof v === 'number' ? v : null,
  );

  const result = {
    labels: [
      '0~9세',
      '10~14세',
      '15~19세',
      '20~24세',
      '25~29세',
      '30~34세',
      '35~39세',
      '40~44세',
      '45~49세',
      '50~54세',
      '55~59세',
      '60~64세',
      '65~69세',
      '70~74세',
    ],
    datasets: [
      {
        label: '남성',
        data: maleData,
        backgroundColor: '#36A2EB',
        hoverBackgroundColor: '#36A2EB',
      },
      {
        label: '여성',
        data: femaleData,
        backgroundColor: '#FF6384',
        hoverBackgroundColor: '#FF6384',
      },
    ],
    text: '23%',
  };

  return (
    <div className="pt-6">
      <div className="flex justify-between">
        <h2 className="font-semibold">
          {statistics?.dong} 연령대별 남/여 생활인구
        </h2>
        <span className="text-sm text-gray-500">
          업데이트 {statistics.date}
        </span>
      </div>
      <Bar data={result} />
    </div>
  );
};

// class BarChart extends React.Component {
//   render() {
//     return (
//       <div>
//         <div className="flex justify-between">
//           <h2 className="font-semibold">시간대별 남/여 생활인구 (행정구)</h2>
//           <span className="text-sm text-gray-500">업데이트 2020.10</span>
//         </div>
//         <Bar data={data} />
//       </div>
//     );
//   }
// }

export default BarChart;
