import {
  Button,
  Chip,
  Divider,
  LoadingScreen,
  Section,
  useIdParam,
  useMutation,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HorizontalADItem } from '.';
import { Ad } from '../types/Ad';
import { companiesAds, CompaniesAdsData, DeletedAd } from '../api/queries';
import { meState } from '../store';
import AddLikeIcon from './AddLikeIcon';
import ModalPopUp from './ModalPopUp';
import InquiryModal from './InquiryModal';
import { ReactComponent as HeartIcon } from '../assets/images/heart-icon.svg';
import { ReactComponent as Alarm } from '../assets/images/alarm.svg';
import { ADStatistics } from './ADStatistics';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';

interface ADDetailPageContentProps {
  ad: Ad;
}

export const ADDetailPageContent: FC<ADDetailPageContentProps> = ({ ad }) => {
  const id = Number(ad.company.id);
  const adId = useIdParam();
  const me = useRecoilValue(meState);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isReport, setIsReport] = useState(false);

  const { loading, error, data } = useQuery<CompaniesAdsData>(companiesAds, {
    variables: {
      id,
    },
  });

  const handleDeleted = () => {
    const answer = window.confirm('정말 삭제하시겠습니까?');
    if (answer) {
      deletedAd();
    } else {
      console.log('취소');
    }
  };

  const [deletedAd] = useMutation(DeletedAd, {
    variables: {
      id: adId,
    },
    onCompleted: () => {
      alert('삭제가 완료 되었습니다.');
      history.push('/company');
    },
  });

  const handleSubmit = () => {
    setIsOpen(true);
  };

  const handleSignUp = () => {
    alert('로그인이 필요합니다.');
    history.push('/login');
  };

  const sendReport = () => {
    setIsReport(true);
  };

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <div className="bg-white">
      <div className="relative">
        <div className="relative pb-2/3 rounded bg-gray-50">
          <ExifOrientationImg
            className="absolute w-full h-full rounded object-cover"
            src={ad.thumbnail}
            alt=""
            style={{ imageOrientation: 'from-image' }}
          />
        </div>

        {/* <div className="absolute top-0 right-0">
          <div className="flex items-center space-x-4 p-4">
            {!me ? (
              <HeartIcon onClick={handleSignUp} />
            ) : (
              <AddLikeIcon ad={ad} />
            )}
            <VertMenu />
          </div>
        </div> */}
      </div>
      <div className="w-full p-4">
        <div className="flex flex-wrap mt-2">{ad.company.name}</div>
        <div className="text-xl truncate text-gray-900 font-bold my-2">
          {ad.title}
        </div>

        <div className="text-base text-gray-600 truncate-2-lines overflow-hidden">
          {ad.description}
        </div>
        <div className="mt-2 text-xl text-gray-800 font-bold truncate-2-lines overflow-hidden">
          {String(ad.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </div>
      </div>
      <Divider />
      <Section>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">매물 번호</div>
          <div className="w-2/3 text-gray-900">{ad.id}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">광고 형태</div>
          <div className="w-2/3 flex flex-wrap">
            {ad.tags.map((tag) => (
              <Chip
                selected={true}
                text={tag.name}
                style={{
                  cursor: 'default',
                  marginBottom: '4px',
                  marginRight: '4px',
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">크기</div>
          <div className="w-2/3 text-gray-900">{ad.adSize}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">기간</div>
          <div className="w-2/3 text-gray-900">{ad.period}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">광고 가능일</div>
          <div className="w-2/3 text-gray-900">
            {ad.possibledAt} ~ {ad.possibledEndAt}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">주소</div>
          <div className="w-2/3 text-gray-900">
            {ad.address} {ad.detailAddress}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-500 flex-shrink-0">등록일</div>
          <div className="w-2/3 text-gray-900">
            {utcToLocalFormat(ad?.createdAt || '', 'YYYY년 MM월 DD일')}
          </div>
        </div>

        {me && (
          <div
            className="text-gray-500 cursor-pointer flex justify-end items-center space-x-2"
            onClick={sendReport}
          >
            <Alarm />
            <span>허위 매물 신고하기 {'>'}</span>
          </div>
        )}

        {isReport ? (
          <ModalPopUp
            ad={ad.id}
            setClose={() => setIsReport(false)}
            title={`허위매물로 신고하시겠습니까?`}
            text1="신고하기"
            alert1="신고가 완료되었습니다."
          />
        ) : (
          ''
        )}
      </Section>
      <Divider height="h-3" />
      <Section>
        <div className="text-lg font-bold text-gray-700 mb-2">상세 내용</div>
        {ad.detailImage && (
          <ExifOrientationImg className="w-full" src={ad.detailImage} alt="" />
        )}
        <div dangerouslySetInnerHTML={{ __html: ad.detailContent }}></div>
      </Section>
      <Divider height="h-3" />
      <Section>
        <div>
          <div className="font-bold text-gray-700 mb-1">위치 및 주변 시설</div>
          <div className="text-sm text-gray-800">
            {ad.address} {ad.detailAddress}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <iframe
            width="100%"
            height="300"
            id="gmap_canvas"
            src={`https://maps.google.com/maps?q=${ad.address}&z=16&ie=UTF8&output=embed`}
            frameBorder="0"
            scrolling="no"
            loading="lazy"
          ></iframe>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={() => {
              window.open(
                `https://maps.google.com/maps?q=${ad.address}&z=16&ie=UTF8&output=embed`,
              );
            }}
          ></div>
        </div>
      </Section>
      <Divider height="h-3" />
      {ad.codeDNG ? (
        <ADStatistics codeDNG={ad.codeDNG} />
      ) : (
        <Section>
          <h3>생활인구 정보를 불러올 수 없는 매물입니다.</h3>
        </Section>
      )}
      <Divider height="h-3" />
      <Section>
        <div className="w-full flex items-center justify-between">
          <div>
            <div className="text-lg font-bold text-gray-700">
              {ad.company?.name}
            </div>
            <div className="text-sm text-gray-500">
              {ad.company?.address}
              {ad.company?.detailAddress}
            </div>
          </div>
          <Link to={`/companies/${ad.company.id}`}>
            <Button style={{ minWidth: 110 }}>상세보기</Button>
          </Link>
        </div>
      </Section>
      <Divider height="h-3" />
      <div className="w-full px-5 py-6 flex items-center justify-between">
        <div className="text-lg font-bold text-gray-700">
          광고사의 다른 매물
        </div>
        <Link to={`/companies/${ad.company.id}`}>
          <div className="text-gray-700">전체보기</div>
        </Link>
      </div>
      <Divider />
      <div className="ml-5 my-5 overflow-x-auto flex space-x-2">
        {data.companiesAds.map((ad) => (
          <HorizontalADItem key={ad.id} ad={ad} />
        ))}
      </div>
      <div className="w-full h-24"></div>
      <div className="w-full md:w-112 z-100 fixed bottom-0 bg-white">
        {me?.id === ad.userId ? (
          <>
            <Button
              style={{ width: '50%' }}
              rounded="rounded-none"
              onClick={() => history.push(`/edit/ad/${ad.id}`)}
            >
              수정
            </Button>
            <Button
              style={{ width: '50%' }}
              variant="outlined"
              rounded="rounded-none"
              onClick={handleDeleted}
            >
              삭제
            </Button>
          </>
        ) : (
          <div className="w-full p-4 flex items-center space-x-4 min-w-max">
            <Button
              style={{ width: '100%' }}
              onClick={!me ? handleSignUp : handleSubmit}
            >
              광고 문의하기
            </Button>
            {me?.role === 'BUSINESS' ? (
              ''
            ) : (
              <>
                {!me ? (
                  <div className="w-13 min-w-13 h-13 flex items-center justify-center bg-pink-5 rounded-lg cursor-pointer">
                    <HeartIcon onClick={handleSignUp} />
                  </div>
                ) : (
                  <div className="w-13 min-w-13 h-13 flex items-center justify-center bg-pink-5 rounded-lg cursor-pointer">
                    <AddLikeIcon ad={ad} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isOpen && <InquiryModal setClose={() => setIsOpen(false)} ad={ad} />}
      {/* {isOpen && !me && (
        <ModalPopUp
          setClose={() => setIsOpen(false)}
          title={`로그인이 필요한 메뉴입니다.`}
          text1="로그인"
          text2="회원 가입"
          link1="/login"
          link2="/signup"
        />
      )}
      {isOpen && me && (
        <InquiryModal setClose={() => setIsOpen(false)} ad={ad} />
      )} */}
    </div>
  );
};
