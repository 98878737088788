import { Button } from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import Image from '../assets/images/notice_1.png';

interface NoticePopUpProps {
  setClose: () => void;
}

const NoticePopUp: FC<NoticePopUpProps> = ({ setClose }) => {
  return (
    <div className="sm:w-4/12 w-3/4 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/3  inset-x-0 p-2 transition transform origin-top-right">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="">
          <div className="text-center w-full pb-2">
            <div className="font-bold text-base mb-4">
              <img className="mx-auto" src={Image} alt="" />
            </div>
            <div className="mx-4">
              <div className="w-full my-2">
                <Button
                  text="확인"
                  style={{ width: '100%' }}
                  onClick={setClose}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticePopUp;
