import {
  BackButton,
  LoadingScreen,
  TopNavbar,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { AD, ADData } from '../api/queries';
import { ADEditContent } from '../components/ADEditContent';
import { LinkadTopNavBar } from '../components/LinkadTopNavBar';

const ADEditPage = () => {
  const id = useIdParam();

  const { loading, error, data } = useQuery<ADData>(AD, {
    variables: {
      id: id,
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <LinkadTopNavBar />
      <div className="max-w-md mx-auto">
        <div className="sm:hidden">
          <TopNavbar title="광고 수정하기" left={<BackButton />} />
        </div>

        <div className="hidden sm:block inset-0">
          <div className=" text-2xl mt-24 text-center">광고 수정하기</div>
        </div>
        <ADEditContent ad={data.ad} />
      </div>
    </>
  );
};

export default ADEditPage;
