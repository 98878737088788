import React from 'react';
import {
  List,
  Divider,
  useRecoilValue,
  useQueryParams,
} from '@entropyparadox/reusable-react';
import { ReactComponent as MapIcon } from '../assets/images/map.svg';
import { FilterAdsList } from '../components/FilterAdsList';
import { meState } from '../store';
import { MenuFilterIcon } from '../components';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../assets/images/back.svg';
import { AdTag } from '../types/AdTag';
import { Ad } from '../types/Ad';

interface ADListPageProps {
  setMapView: (mapView: boolean) => void;
  places?: Ad[];
}

export const ADListPage: React.FC<ADListPageProps> = ({
  setMapView,
  places,
}) => {
  const me = useRecoilValue(meState);

  const { ids } = useQueryParams();
  console.log('filter', ids);

  if (!places) return <></>;

  return (
    <>
      <div className="sm:hidden ">
        <div className="flex items-center justify-between mx-5">
          <div
            className="font-bold flex items-center space-x-3 cursor-pointer py-4 w-full"
            onClick={() => setMapView(true)}
          >
            <ChevronLeft /> <div>이 지역 리스트</div>
          </div>
          <Link to="/filter">
            {ids ? <MenuFilterIcon active /> : <MenuFilterIcon />}
          </Link>
        </div>
        <Divider height="h-3" />
      </div>
      <div className="hidden sm:block">
        <div className="text-xl font-bold py-4 px-5">
          <div className="mb-5 flex justify-between">
            <div>
              <span className="text-brand-1">지역</span>의 매물 정보
            </div>
            <Link to="/filter" className="hidden sm:block">
              {ids ? <MenuFilterIcon active /> : <MenuFilterIcon />}
            </Link>
          </div>
          <Divider />
        </div>
      </div>

      <List style={{ height: '79vh', overflowY: 'scroll' }}>
        {places.length === 0 ? (
          <p className="flex justify-center items-center h-full text-gray-500">
            검색된 매물이 없습니다.
          </p>
        ) : (
          places.map((place) => (
            <FilterAdsList key={place.id} ad={place} me={me} />
          ))
        )}
      </List>
      <div className="fixed bottom-0 w-full sm:hidden">
        <div className="w-full flex items-center justify-center">
          <div
            className="rounded-full w-max flex items-center space-x-2 bg-white mb-5 py-2 px-5 shadow-md border cursor-pointer"
            onClick={() => setMapView(true)}
          >
            <MapIcon />
            <div className="text-brand-1">지도로 보기</div>
          </div>
        </div>
      </div>
    </>
  );
};
