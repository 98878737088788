import {
  Button,
  HamburgerButton,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { HambergerMenu, LinkadTopNavBar } from '../components';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';
import { resetNewPassword } from '../api';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

interface matchParams {
  token: string;
}

const EmailChangePassword: React.FC<RouteComponentProps<matchParams>> = ({
  match,
}) => {
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [comfirmNewPassword, setComfirmNewPassword] = useState('');
  const history = useHistory();

  const [ResetNewPassword] = useMutation(resetNewPassword, {
    variables: {
      token: match.params.token,
      newPassword,
    },
    onCompleted: async () => {
      alert('비밀번호 변경이 완료되었습니다. 재로그인 해주세요.');
      history.push('/');
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  return (
    <>
      <div className="sm:hidden">
        <TopNavbar
          title="비밀번호 변경"
          right={<HamburgerButton onClick={() => setOpen(true)} />}
          left={<LogoIcon className="h-8 w-28" />}
        />
      </div>
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div className="hidden sm:block inset-0">
        <LinkadTopNavBar />
        <div className=" text-2xl mt-24 text-center">비밀번호 변경</div>
      </div>

      <div className="max-w-md mx-auto">
        <Section>
          <TextField
            type="password"
            label="*새로운 비밀번호"
            placeholder="비밀번호를 입력해주세요."
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            type="password"
            label="*새로운 비밀번호 확인"
            placeholder="비밀번호를 재입력해주세요."
            value={comfirmNewPassword}
            onChange={(e) => setComfirmNewPassword(e.target.value)}
          />
          <div></div>
          <Button
            text="비밀번호 변경"
            style={{ marginTop: 20 }}
            onClick={() => ResetNewPassword()}
          ></Button>
        </Section>
      </div>
    </>
  );
};

export default EmailChangePassword;
