import React from 'react';
import { ReactComponent as MenuFilter } from '../assets/images/menu-filter.svg';

interface MenuFilterIconProps {
  active?: Boolean;
}

export const MenuFilterIcon: React.FC<MenuFilterIconProps> = ({ active }) => {
  return (
    <div
      className={`w-9 h-9 min-w-9 rounded border border-brand-1 flex items-center justify-center ${
        active ? 'bg-brand-1' : 'bg-white'
      }`}
    >
      {active ? <MenuFilter stroke="white" fill="#7950F2" /> : <MenuFilter />}
    </div>
  );
};
