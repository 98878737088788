import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  LoginPage,
  SignupPage,
  MyPage,
  CompanyMyPage,
  SearchingPage,
} from './pages';
import {
  AuthRoute,
  useApolloClient,
  useAuth,
  useRecoilState,
} from '@entropyparadox/reusable-react';
import { meState } from './store';
import { ME } from './api';
import ADRequest from './pages/ADRequest';
import HomePage from './pages/HomePage';
import ADAddPage from './pages/ADAddPage';
import ADEditPage from './pages/ADEditPage';
import FindPassword from './pages/FindPassword';
import ChangePassword from './pages/ChangePassword';
import EmailChangePassword from './pages/EmailChangePassword';

function App() {
  const client = useApolloClient();
  const { authenticated } = useAuth();
  const [me, setMe] = useRecoilState(meState);

  useEffect(() => {
    if (authenticated) {
      client
        .query({ query: ME })
        .then(({ data: { me } }) => setMe(me))
        .catch((error) => console.log(error));
    } else if (me) {
      setMe(undefined);
      client.clearStore();
    }
  }, [client, authenticated, me, setMe]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/reset-password/:token" component={EmailChangePassword} />
        <Route path="/created/ad" component={ADAddPage} />
        <Route path="/edit/ad/:id" component={ADEditPage} />
        <Route path="/login/find" component={FindPassword} />
        <AuthRoute path="/login" component={LoginPage} guestOnly />
        <AuthRoute path="/company" component={CompanyMyPage} />
        <AuthRoute path="/signup" component={SignupPage} guestOnly />
        <AuthRoute path="/changepw" component={ChangePassword} />
        <Route path="/mypage/" component={MyPage} />
        <Route path="/search" component={SearchingPage} />
        <Route path="/request" component={ADRequest} />
        <Route path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
