import React from 'react';
import DaumPostcode from 'react-daum-postcode';

interface DaumPostProps {
  setAddress: (fullAddress: any) => void;
  setIsOpen: () => void;
}

export const DaumPost: React.FC<DaumPostProps> = ({
  setAddress,
  setIsOpen,
}) => {
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setIsOpen();
    setAddress(fullAddress);
  };
  return (
    <div
      style={{
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100vh',
        zIndex: 1000,
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          background: '#fff',
          borderRadius: '10px',
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '450px',
          width: '330px',
        }}
      >
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          <div style={{ width: '300px' }}>주소 검색</div>
          <button
            style={{ textAlign: 'right', float: 'right', marginRight: 'auto' }}
            onClick={() => setIsOpen()}
          >
            X
          </button>
        </div>
        <hr />
        <DaumPostcode onComplete={handleComplete} />
      </div>
    </div>
  );
};
