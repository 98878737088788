import React, { useState } from 'react';
import {
  Button,
  RadioGroup,
  Radio,
  LoadingScreen,
  useMutation,
  useLogin,
  Blank,
  useSetRecoilState,
  tokenState,
  useRecoilValue,
  kakaoIdState,
} from '@entropyparadox/reusable-react';
import { LinkadTopNavBar } from '../components';
import { useQuery, gql } from '@apollo/client';
import { Category } from '../types/Category';
import SingUpForm from '../components/SingUpForm';
import { SignUpBusiness, SignUpUser } from '../api';
import { Role } from '../types/User';
import { useHistory } from 'react-router-dom';

const SignUpPageQuery = gql`
  query categories {
    categories {
      id
      title
    }
  }
`;

export const SignupPage: React.FC = () => {
  const [role, setRole] = useState(Role.USER);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setorganization] = useState('');
  const [category, setCategory] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [homepage, setHomepage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [license, setLicense] = useState<File | null | undefined>();
  const [agree, setAgree] = useState(false);
  const [code, setCode] = useState('');
  const [phoneId, setPhoneId] = useState('');
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [place, setPlace] = useState(false);
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();
  const kakaoId = useRecoilValue(kakaoIdState);

  const [errorMessage, setErrorMessage] = useState('');

  const login = useLogin(email, password);

  const { loading, error, data } = useQuery<{
    categories: Category[];
  }>(SignUpPageQuery);

  const [signUpUser] = useMutation(SignUpUser, {
    variables: {
      email,
      password,
      name,
      phone,
      agree,
      organization,
      kakaoId,
      categoryId: Number(category),
    },
    onCompleted: async ({ signupUser: { token } }) => {
      alert('회원가입이 완료 되었습니다.');
      await setToken(token);
    },
  });

  const [signUpBusiness] = useMutation(SignUpBusiness, {
    variables: {
      email,
      password,
      name,
      phone,
      companyName,
      address,
      detailAddress,
      homepage,
      kakaoId,
      license: license,
    },
    onCompleted: async ({ signupBusiness: { token } }) => {
      alert('회원가입이 완료 되었습니다.');
      await setToken(token);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      {loading && <Blank />}
      <LinkadTopNavBar border="border-gray-300" />
      <div className="min-h-screen flex flex-col sm:px-6 lg:px-8">
        <div className="mt-4 md:mt-16 sm:mx-auto sm:max-w-md sm:w-160">
          <section
            className={`flex flex-col items-stretch space-y-6 px-5 py-4 bg-white`}
          >
            <div className="text-2xl font-bold text-gray-800 py-3 mx-auto">
              회원가입
            </div>
            <RadioGroup
              label="사용자 유형"
              onChange={(e) => setRole(e.target.value as Role)}
            >
              <Radio
                label="일반회원"
                value={Role.USER}
                checked={role === Role.USER}
              />
              <div className="w-4" />
              <Radio
                label="광고회사(대행사, 매체사, 매체주)"
                value={Role.BUSINESS}
                checked={role === Role.BUSINESS}
              />
            </RadioGroup>
            <SingUpForm
              name={name}
              setName={(e) => setName(e)}
              phone={phone}
              setPhone={(e) => setPhone(e)}
              authenticationNumber={authenticationNumber}
              setAuthenticationComplet={(e) => setAuthenticationComplet(e)}
              authenticationComplet={authenticationComplet}
              setAuthenticationNumber={(e) => setAuthenticationNumber(e)}
              role={role}
              organization={organization}
              setorganization={(e) => setorganization(e)}
              category={data.categories}
              setCategory={(e) => setCategory(e)}
              email={email}
              setEmail={(e) => setEmail(e)}
              password={password}
              setPassword={(e) => setPassword(e)}
              passwordAgain={passwordAgain}
              setPasswordAgain={(e) => setPasswordAgain(e)}
              companyName={companyName}
              setCompanyName={(e) => setCompanyName(e)}
              address={address}
              setAddress={(e) => setAddress(e)}
              detailAddress={detailAddress}
              setDetailAddress={(e) => setDetailAddress(e)}
              homepage={homepage}
              setHomepage={(e) => setHomepage(e)}
              confirm={confirm}
              setConfirm={(e) => setConfirm(e)}
              license={license}
              setLicense={(e) => setLicense(e)}
              agree={agree}
              setAgree={(e) => setAgree(e)}
              place={place}
              setPlace={(e) => setPlace(e)}
            />
            <div className="text-red-500">{errorMessage}</div>
            <Button
              disabled={
                !name ||
                !authenticationComplet ||
                (role === Role.USER && !organization) ||
                (role === Role.USER && !category) ||
                (role === Role.BUSINESS && !companyName) ||
                (role === Role.BUSINESS && !address) ||
                (role === Role.BUSINESS && !detailAddress) ||
                (role === Role.BUSINESS && !license) ||
                !email ||
                !place ||
                !password ||
                !confirm
              }
              onClick={() => {
                if (password !== passwordAgain) {
                  setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
                } else {
                  setLoading(true);
                  role === Role.USER
                    ? signUpUser()
                        .then((res) => {
                          console.log('res', res);
                          if (res) {
                            const { data, errors } = res;
                            if (data?.signUpUser.token) {
                              history.push('/');
                            } else {
                              setErrorMessage(
                                errors
                                  ?.map((el: any) => el.message)
                                  .join(', ') || '',
                              );
                            }
                          } else {
                            setLoading(false);
                            setErrorMessage(
                              '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요!',
                            );
                          }
                        })
                        .catch((err) => {
                          console.log('err', err);
                          setLoading(false);
                          if (
                            err?.message.includes(
                              'duplicate key value violates unique constraint',
                            )
                          ) {
                            setErrorMessage(
                              '이미 가입된 이메일입니다. 로그인을 시도해주세요!',
                            );
                          } else if (
                            err?.message.includes('Received status code 400')
                          ) {
                            setErrorMessage('필수 정보를 입력해주세요!');
                          } else {
                            setErrorMessage(err?.message || '');
                          }
                        })
                    : signUpBusiness()
                        .then((res) => {
                          console.log('res', res);
                          if (res) {
                            const { data, errors } = res;
                            if (data?.SignUpBusiness?.token) {
                              history.push('/');
                            } else {
                              setErrorMessage(
                                errors
                                  ?.map((el: any) => el.message)
                                  .join(', ') || '',
                              );
                            }
                          } else {
                            setLoading(false);
                            setErrorMessage(
                              '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요!',
                            );
                          }
                        })
                        .catch((err) => {
                          console.log('err', err);
                          setLoading(false);
                          if (
                            err?.message.includes(
                              'duplicate key value violates unique constraint',
                            )
                          ) {
                            setErrorMessage(
                              '이미 가입된 이메일입니다. 로그인을 시도해주세요!',
                            );
                          } else if (
                            err?.message.includes('Received status code 400')
                          ) {
                            setErrorMessage('필수 정보를 입력해주세요!');
                          } else {
                            setErrorMessage(err?.message || '');
                          }
                        });
                }
              }}
            >
              회원가입
            </Button>
          </section>
        </div>
      </div>
    </>
  );
};
