import React, { useState } from 'react';
import {
  TopNavbar,
  Button,
  useLogout,
  HamburgerButton,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';
import { ReactComponent as LinkAds } from '../assets/images/link-ads.svg';
import { Link, useHistory } from 'react-router-dom';
import { HambergerMenu } from './index';
import { useAuth } from '@entropyparadox/reusable-react';
import { meState } from '../store';
import { Role } from '../types/User';

interface LinkadTopNavBarProps {
  border?: 'border-gray-500' | 'border-gray-300';
}

export const LinkadTopNavBar: React.FC<LinkadTopNavBarProps> = ({ border }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { authenticated } = useAuth();
  const logout = useLogout();
  const me = useRecoilValue(meState);

  const handleLogOut = () => {
    logout();
    history.push('/');
  };

  const handleSignUp = () => {
    alert('로그인이 필요합니다.');
    history.push('/login');
  };

  return (
    <>
      <div className="md:hidden z-20">
        <TopNavbar
          left={
            <Link to="/">
              <div className="flex items-center space-x-2">
                <LogoIcon className="h-8 w-28" />
              </div>
            </Link>
          }
          right={
            <div className="flex items-center space-x-4">
              {!me ? (
                <div
                  className="font-bold text-brand-1 min-w-max cursor-pointer"
                  onClick={handleSignUp}
                >
                  매물요청
                </div>
              ) : (
                me.role === Role.USER && (
                  <Link to="/request">
                    <div className="font-bold text-brand-1 min-w-max">
                      매물요청
                    </div>
                  </Link>
                )
              )}
              <HamburgerButton onClick={() => setOpen(true)} />
            </div>
          }
        />
      </div>
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div
        className={`hidden md:flex z-20 fixed top-0 w-full justify-between items-center text-center px-4 font-spoqa font-bold text-gray-1 text-lg bg-white h-16 ${border} ${
          border ? 'border-b-2' : ''
        }`}
      >
        <div className="flex-grow flex justify-start items-center flex-row p-1">
          <Link to="/">
            <div className="flex items-center">
              <LogoIcon className="w-28 h-24 lg:w-36 lg:h-28" />
            </div>
          </Link>
        </div>
        <div className="flex-shrink flex justify-center items-center flex-row p-1">
          <div className="flex items-center space-x-4 lg:space-x-6 xl:space-x-8 font-normal">
            <Link to="/search">
              <div className="w-max text-md lg:text-base">매물검색</div>
            </Link>
            <a
              href="https://linkad.channel.io"
              target="blank"
              className="w-max text-md lg:text-base"
            >
              고객센터
            </a>
            <a
              href="https://www.notion.so/a8ac5178cdf84ef6bec79fc64a0d3ae2"
              target="blank"
              className="w-max text-md lg:text-base"
            >
              회사소개
            </a>
            <a
              href="https://www.notion.so/f2277583e4544be481003f6e630cb141"
              target="blank"
              className="w-max text-md lg:text-base"
            >
              공지사항
            </a>
            {!me ? (
              <div
                className="w-max text-md lg:text-base text-brand-1 cursor-pointer"
                onClick={handleSignUp}
              >
                매물요청
              </div>
            ) : (
              me.role === Role.USER && (
                <Link to="/request">
                  <div className="w-max text-md lg:text-base text-brand-1">
                    매물요청
                  </div>
                </Link>
              )
            )}
            {me?.role === Role.BUSINESS && (
              <div
                className="w-max text-md lg:text-base text-brand-1 cursor-pointer"
                onClick={() =>
                  me?.isActive
                    ? history.push('/created/ad')
                    : alert('관리자의 승인을 기다리는 중입니다.')
                }
              >
                광고등록
              </div>
            )}
          </div>
        </div>
        <div className="flex-grow flex justify-end items-center flex-row p-1">
          <div className="flex items-center space-x-2">
            {authenticated ? (
              <>
                {me?.role === Role.BUSINESS && (
                  <div
                    className="w-max text-md lg:text-base mr-3 cursor-pointer"
                    onClick={() => history.push('/company')}
                  >
                    마이페이지
                  </div>
                )}
                {me?.role === Role.USER && (
                  <Link to="/mypage">
                    <div className="w-max text-md lg:text-base mr-3">
                      마이페이지
                    </div>
                  </Link>
                )}
                <div
                  className="w-max text-md lg:text-base cursor-pointer"
                  onClick={handleLogOut}
                >
                  로그아웃
                </div>
              </>
            ) : (
              <>
                <div
                  className="text-md lg:text-base  mr-3 cursor-pointer"
                  onClick={() => history.push('/login')}
                >
                  로그인
                </div>
                <Button to="/signup" height="h-11">
                  회원가입
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
