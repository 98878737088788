import React, { useEffect, useState } from 'react';
import {
  Section,
  TextField,
  Button,
  useLogin,
  useMutation,
  useSetRecoilState,
  tokenState,
  useRecoilValue,
  kakaoIdState,
  useLoginWithKakaoWeb,
  useShowAlert,
} from '@entropyparadox/reusable-react';
import { LinkadTopNavBar } from '../components';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';
import { ReactComponent as LinkAds } from '../assets/images/link-ads.svg';
import { Link, useHistory } from 'react-router-dom';
import { LOGIN } from '../api';

export const LoginPage: React.FC = () => {
  const kakaoId = useRecoilValue(kakaoIdState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();
  const showAlert = useShowAlert();
  const loginWithKakaoWeb = useLoginWithKakaoWeb((error) => {
    console.log(error);
    showAlert('error', error.message);
  });
  useEffect(() => {
    if (kakaoId) {
      history.push('/signup');
    }
  }, [history, kakaoId]);

  const [login] = useMutation(LOGIN, {
    variables: {
      email,
      password,
    },
    onCompleted: async ({ login }) => {
      if (login?.token) {
        await setToken(login?.token);
      }
    },
    onError: async (error) => {
      if (error?.message === 'Unauthorized') {
        alert('이메일 혹은 비밀번호가 일치하지 않습니다!');
      }
    },
  });

  const handleLogin = async (e: any) => {
    e.preventDefault();
    await login();
  };

  return (
    <>
      <LinkadTopNavBar border="border-gray-300" />
      <div className="block ps:hidden">
        <Section>
          <form onSubmit={(e) => handleLogin(e)}>
            <div className="text-2xl font-bold text-gray-800 py-4">로그인</div>
            <TextField
              id="email"
              type="email"
              name="email"
              label="이메일"
              placeholder="이메일을 입력해주세요"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="password"
              label="비밀번호"
              name="password"
              placeholder="비밀번호를 입력해주세요"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="w-full text-right pb-4">
              <Link to="/login/find">
                <div className="text-brand-1">비밀번호 찾기</div>
              </Link>
            </div>
            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={!email || !password}
              onClick={async () => await login()}
            >
              로그인
            </Button>
          </form>
          <Link to="/signup">
            <Button variant="outlined" style={{ width: '100%' }}>
              회원가입
            </Button>
          </Link>
          <Button
            text="카카오로 시작하기"
            theme="kakao"
            onClick={() => loginWithKakaoWeb()}
          />
        </Section>
      </div>
      <div className="hidden ps:block">
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center sm:px-6 lg:px-8">
          <div className="-mt-24 md:mt-0 sm:my-0 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex items-center space-x-4 justify-center">
              <LogoIcon className="w-40 h-16" />
            </div>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-6 px-2 shadow sm:rounded-lg sm:px-10">
              <Section>
                <form onSubmit={(e) => handleLogin(e)}>
                  <TextField
                    label="이메일"
                    type="email"
                    placeholder="이메일을 입력해주세요"
                    style={{ marginBottom: '0.5rem' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="비밀번호"
                    placeholder="비밀번호를 입력해주세요"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="w-full text-right pb-4">
                    <Link to="/login/find">
                      <div className="text-brand-1">비밀번호 찾기</div>
                    </Link>
                  </div>
                  <Button
                    disabled={!email || !password}
                    onClick={async () => {
                      await login();
                    }}
                    type="submit"
                    style={{ width: '100%' }}
                  >
                    로그인
                  </Button>
                </form>
                <Link to="/signup">
                  <Button variant="outlined" style={{ width: '100%' }}>
                    회원가입
                  </Button>
                </Link>
                <Button
                  text="카카오로 시작하기"
                  theme="kakao"
                  onClick={() => loginWithKakaoWeb()}
                />
              </Section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
