import { List, LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React from 'react';
import { likesUser, LikesData } from '../api';
import { ADItem } from '../components';

const MyPageLikeList = () => {
  const { loading, error, data } = useQuery<LikesData>(likesUser);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <List style={{ height: '70vh' }}>
      {data.likesByUserId.length === 0 ? (
        <div className="flex justify-center items-center h-screen text-gray-500">
          찜한 매물이 없습니다.
        </div>
      ) : (
        data.likesByUserId.map((ad) => <ADItem ad={ad.ad} />)
      )}

      {console.log(data.likesByUserId, 22)}
    </List>
  );
};

export default MyPageLikeList;
