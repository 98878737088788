import {
  LoadingScreen,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { CreateLike, DeleteLike, LikeData, LikesByUserId } from '../api';
import { ReactComponent as HeartIcon } from '../assets/images/heart-icon.svg';
import { ReactComponent as HeartColorIcon } from '../assets/images/heart-icon-color.svg';
import { Ad } from '../types/Ad';

interface AddLikeIconProps {
  ad: Ad;
}

const AddLikeIcon: FC<AddLikeIconProps> = ({ ad }) => {
  const { loading, error, data } = useQuery<LikeData>(LikesByUserId, {
    variables: { adId: ad.id },
  });

  const [deleteLike] = useMutation(DeleteLike, {
    variables: { adId: ad.id },
    refetchQueries: [{ query: LikesByUserId, variables: { adId: ad.id } }],
  });

  const [createLike] = useMutation(CreateLike, {
    variables: { adId: ad.id },
    refetchQueries: [{ query: LikesByUserId, variables: { adId: ad.id } }],
  });

  if (loading) return <LoadingScreen />;
  // if (error) return <></>;

  return (
    <>
      {data?.likeByAdIdUserId?.adId === ad.id ? (
        <HeartColorIcon onClick={() => deleteLike()} />
      ) : (
        <HeartIcon onClick={() => createLike()} />
      )}
    </>
  );
};

export default AddLikeIcon;
