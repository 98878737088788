import React from 'react';
import {
  Button,
  Section,
  Divider,
  TopFixedModal,
  useAuth,
  useLogout,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';
import { ReactComponent as LinkAds } from '../assets/images/link-ads.svg';
import { Link, useHistory } from 'react-router-dom';
import { meState } from '../store';
import { Role } from '../types/User';

interface HambergerMenuProps {
  isOpen: boolean;
  setClose: () => void;
}
export const HambergerMenu: React.FC<HambergerMenuProps> = ({
  isOpen,
  setClose,
}) => {
  const { authenticated } = useAuth();
  const logout = useLogout();
  const me = useRecoilValue(meState);
  const history = useHistory();

  const handleLogOut = () => {
    logout();
    history.push('/');
  };

  return (
    <TopFixedModal
      isOpen={isOpen}
      setClose={setClose}
      header={
        <Link to="/" onClick={setClose}>
          <div className="flex items-center space-x-2">
            <LogoIcon className="h-10 w-28" />
          </div>
        </Link>
      }
    >
      <div className="flex flex-col items-stretch space-y-4 mb-4">
        <Link to="/search">
          <div className="font-bold text-gray-800">매물검색</div>
        </Link>
        <a
          href="https://linkad.channel.io"
          target="blank"
          className="font-bold text-gray-800"
        >
          고객센터
        </a>
        <a
          href="https://www.notion.so/a8ac5178cdf84ef6bec79fc64a0d3ae2"
          target="blank"
          className="font-bold text-gray-800"
        >
          회사소개
        </a>
        <a
          href="https://www.notion.so/f2277583e4544be481003f6e630cb141"
          target="blank"
          className="font-bold text-gray-800"
        >
          공지사항
        </a>
        {authenticated && (
          <>
            {me?.role === Role.BUSINESS && (
              <>
                <div
                  className="font-bold text-gray-800"
                  onClick={() =>
                    me?.isActive
                      ? history.push('/created/ad')
                      : alert('관리자의 승인을 기다리는 중입니다.')
                  }
                >
                  광고등록
                </div>
                <Link to="/company">
                  <div className="font-bold text-gray-800">마이페이지</div>
                </Link>
              </>
            )}
            {me?.role === Role.USER && (
              <Link to="/mypage">
                <div className="font-bold text-gray-800">마이페이지</div>
              </Link>
            )}
            <div
              className="font-bold text-gray-800 cursor-pointer"
              onClick={handleLogOut}
            >
              로그아웃
            </div>
          </>
        )}
      </div>
      {!authenticated && (
        <>
          <div className="w-full my-4">
            <Button to="/login" style={{ width: '100%' }}>
              로그인
            </Button>
          </div>
          <div className="w-full flex items-center justify-center space-x-2 text-sm mb-4">
            <div className="text-gray-600">아직 회원이 아니신가요?</div>
            <Link to="/signup">
              <div className="text-brand-1 font-bold">회원가입</div>
            </Link>
          </div>
        </>
      )}

      <Divider />
      <div className="flex flex-wrap items-center my-4 text-sm">
        <a
          href="https://www.notion.so/fa69d4d7363a4717ad2a3eb71bc46b37"
          target="blank"
          className="text-gray-600 mr-4"
        >
          이용약관
        </a>
        <a
          href="https://www.notion.so/e06f92768efe47f7a7dae50086ddd3d0"
          target="blank"
          className="text-gray-600 mr-4"
        >
          개인정보 처리방침
        </a>
        <a
          href="https://www.notion.so/18b444ffe3bf4211914e6a9ff7ecc638"
          target="blank"
          className="text-gray-600 mr-4"
        >
          위치기반 서비스 이용약관
        </a>
        <a
          href="https://www.notion.so/fed80a03b9b34392a530fc4a1aa89513"
          target="blank"
          className="text-gray-600 mr-4"
        >
          마케팅 수신 동의
        </a>
      </div>
      <div className="text-gray-400 text-xs">
        사업자등록번호 : 699-87-01697
        <br />
        통신판매업신고번호 : 제 2020-서울용산-2066호
        <br />
        주소 : 서울특별시 용산구 대사관로 34길 21 영풍빌딩, 5층, 8호
      </div>
    </TopFixedModal>
  );
};
