import {
  Divider,
  List,
  ListItem,
  Section,
} from '@entropyparadox/reusable-react';
import React from 'react';

const CompanyPaymentPage = () => {
  return (
    <>
      <Section>
        <div className="flex space-x-4 items-center">
          <div className="text-gray-900 font-bold">이용중인 상품</div>
          <div className="text-gray-500 text-sm">다른 상품 보기 &gt;</div>
        </div>
        <div className="relative mx-10 my-4 pb-2/5">
          <div className="absolute w-full h-full object-cover">
            <div className="bg-brand-2 w-full h-full text-brand-3 flex items-center justify-center font-bold rounded-lg">
              유료회원-Pro
            </div>
          </div>
        </div>
      </Section>

      <Divider height="h-3" />
      <List>
        <ListItem>
          <div className="relative w-full h-full text-left">
            <div className="rounded-xl bg-darkgreen-5 text-darkgreen-1 w-max px-3 py-1 font-bold text-sm">
              결제완료
            </div>
            <div className="font-bold text-gray-900 text-lg mt-1 mb-2">
              이용권 구매
            </div>
            <div className="text-gray-600">
              결제일자 : 2020.09.22 <br />
              결제방법 : 카드결제 <br />
              이용일 : ~2020.10.22
            </div>
            <div className="absolute bottom-0 right-0 w-max font-bold text-gray-800 text-lg">
              20,000원
            </div>
          </div>
        </ListItem>
        <ListItem>
          <div className="relative w-full h-full text-left">
            <div className="rounded-xl bg-darkgreen-5 text-darkgreen-1 w-max px-3 py-1 font-bold text-sm">
              결제완료
            </div>
            <div className="font-bold text-gray-900 text-lg mt-1 mb-2">
              이용권 구매
            </div>
            <div className="text-gray-600">
              결제일자 : 2020.09.22 <br />
              결제방법 : 카드결제 <br />
              이용일 : ~2020.10.22
            </div>
            <div className="absolute bottom-0 right-0 w-max font-bold text-gray-800 text-lg">
              20,000원
            </div>
          </div>
        </ListItem>
      </List>
      <div className="text-gray-600 p-4">
        *이용권 변경/해지를 원할 시, 오른쪽 하단의 상담 버튼을 이용해주세요.
      </div>
    </>
  );
};

export default CompanyPaymentPage;
