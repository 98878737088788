import {
  Button,
  PhoneNumberField,
  Section,
  TextField,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  CreatePhone,
  updateBusinessUser,
  updateCompany,
  UpDatePhoneVerify,
} from '../api/queries';
import { DaumPost } from '../components/DaumPost';
import { Company } from '../types/Company';
import { User } from '../types/User';

interface CompanyEditPageProps {
  me: User;
  company?: Company;
}

const CompanyEditPage: React.FC<CompanyEditPageProps> = ({ me, company }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(company?.name);
  const [phone, setPhone] = useState(me.phone);
  const [address, setAddress] = useState(company?.address);
  const [detailAddress, setDetailAddress] = useState(company?.detailAddress);
  const [homepage, setHomepage] = useState(company?.homepage);
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [code, setCode] = useState('');
  const [phoneId, setPhoneId] = useState('');
  const id = company?.id;

  const history = useHistory();

  const [UPDATEBUSINESSUSER] = useMutation(updateBusinessUser, {
    variables: {
      phone: phone || me?.phone,
    },
  });

  const [UPDATECOMPANY] = useMutation(updateCompany, {
    variables: {
      id: Number(id),
      name: name,
      address: address,
      detailAddress: detailAddress,
      homepage: homepage,
    },
  });

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setPhoneId(createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const handleSubmit = async () => {
    if (phone === me.phone) {
      await UPDATEBUSINESSUSER();
      await UPDATECOMPANY();
      alert('수정이 완료 되었습니다.');
      window.location.reload();
    }
    if (authenticationComplet) {
      await UPDATEBUSINESSUSER();
      await UPDATECOMPANY();
      alert('수정이 완료 되었습니다.');
      window.location.reload();
    }
  };

  return (
    <>
      <div className="w-full pt-4">
        <div className="text-xl truncate text-gray-800 font-bold my-1 px-5">
          내 프로필
        </div>
      </div>
      <Section>
        <TextField label="이름" value={me.name} disabled />
        <PhoneNumberField
          label="*핸드폰 번호"
          value={phone}
          disabled
          onChange={(e) => setPhone(e.target.value)}
        />
        <p style={{ marginTop: 3, fontSize: 12, color: '#727272' }}>
          * 연락처를 수정하시려면 고객센터로 문의 바랍니다.
        </p>
        {/* {authenticationNumber ? (
          <>
            {!authenticationComplet ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() =>
                    phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
                  }
                >
                  인증번호 재전송
                </Button>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 mt-1">
                    <input
                      type="text"
                      name=""
                      className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                      placeholder="인증번호를 입력해주세요."
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button
                      className="col-span-1 bg-brand-1 rounded-md text-white"
                      onClick={() =>
                        code
                          ? upDatePhoneVerify()
                          : alert('인증번호를 입력해주세요.')
                      }
                    >
                      확인
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {authenticationComplet && <div>* 인증이 완료되었습니다.</div>}
              </>
            )}
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() =>
              phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
            }
          >
            인증번호 보내기
          </Button>
        )} */}

        <TextField label="*이메일" value={me.email} disabled />
        <TextField
          label="회사명"
          placeholder="회사명을 입력해주세요."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="flex items-end space-x-4">
          <div className="w-full">
            <TextField
              label="회사주소"
              placeholder="주소를 입력해주세요."
              value={address}
              disabled
            />
          </div>
          <div className="min-w-max">
            <Button onClick={() => setIsOpen(true)}>주소 검색</Button>
          </div>
          {isOpen && (
            <DaumPost
              setAddress={(fullAddress) => setAddress(fullAddress)}
              setIsOpen={() => setIsOpen(false)}
            />
          )}
        </div>
        <TextField
          label="상세주소"
          placeholder="상세주소를 입력해주세요."
          value={detailAddress}
          onChange={(e) => setDetailAddress(e.target.value)}
        />
        <TextField
          label="홈페이지"
          placeholder="홈페이지 주소를 입력해주세요."
          value={homepage}
          onChange={(e) => setHomepage(e.target.value)}
        />
        <Button
          variant="outlined"
          onClick={() => {
            history.push('/changepw');
          }}
        >
          비밀번호 변경
        </Button>
        <Button onClick={handleSubmit}>수정 완료</Button>
      </Section>
    </>
  );
};

export default CompanyEditPage;
