import {
  BottomFixedButton,
  Chip,
  CloseButton,
  Section,
  TopNavbar,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag } from '../types/Tag';

interface FilteringPageContentProps {
  tags: Tag[];
}

export const FilteringPageContent: FC<FilteringPageContentProps> = ({
  tags,
}) => {
  const history = useHistory();

  const searchIds = window.localStorage.getItem('selectedTagIds');
  const ids = searchIds?.split(',').map((id) => Number(id));

  const [selectedTagIds, setSelectedTagIds] = useState(new Set<number>(ids));

  const handleSearch = () => {
    history.replace(`/?ids=${Array.from(selectedTagIds).join()}`);
    window.localStorage.setItem(
      'selectedTagIds',
      Array.from(selectedTagIds).join(),
    );
  };

  const handleReset = () => {
    setSelectedTagIds(new Set<number>());
    window.localStorage.removeItem('selectedTagIds');
  };

  return (
    <>
      <TopNavbar
        left={
          <div
            className="text-gray-500 cursor-pointer"
            onClick={handleReset}
            style={{ marginLeft: '0.75rem' }}
          >
            초기화
          </div>
        }
        right={<CloseButton />}
      />
      <Section>
        <div className="text-gray-800 font-bold text-lg">광고 매물 필터</div>
        <div className="flex flex-wrap">
          {tags.map((tag) => (
            <Chip
              key={tag.id}
              text={tag.name}
              style={{ margin: '0.25rem' }}
              selected={selectedTagIds.has(tag.id)}
              onClick={() => {
                const newValue = new Set(selectedTagIds);
                if (selectedTagIds.has(tag.id)) {
                  newValue.delete(tag.id);
                } else {
                  newValue.add(tag.id);
                }
                setSelectedTagIds(newValue);
              }}
            />
          ))}
        </div>
      </Section>
      <BottomFixedButton
        // disabled={selectedTagIds.size === 0} // 초기화 후 적용할 수 있어야 함
        onClick={() => handleSearch()}
        style={{ position: 'absolute' }}
      >
        적용하기
      </BottomFixedButton>
    </>
  );
};
