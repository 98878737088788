import React, { useState } from 'react';
import {
  TopNavbar,
  Section,
  BackButton,
  TextField,
  Button,
  useMutation,
} from '@entropyparadox/reusable-react';
import { useHistory } from 'react-router-dom';
import { LinkadTopNavBar } from '../components';
import { findAd } from '../api';

export const SearchingPage: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();

  const [FindAd] = useMutation(findAd, {
    variables: {
      id: Number(searchValue),
    },
    onCompleted: async () => {
      history.push(`/ads/${Number(searchValue)}`);
    },
    onError: async (err) => {
      alert(err.message);
    },
  });

  return (
    <>
      <LinkadTopNavBar />

      <div className="sm:hidden">
        <TopNavbar title="매물 검색" left={<BackButton />} />
      </div>
      <div className="hidden sm:block inset-0">
        <div className="text-2xl mt-15 md:mt-24 text-center">매물 검색</div>
      </div>

      <div className="max-w-md mx-auto">
        <Section>
          <TextField
            autoFocus
            label="매물번호"
            placeholder="매물 번호를 입력해주세요"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            disabled={searchValue.length > 0 ? false : true}
            onClick={() => FindAd()}
          >
            매물 찾기
          </Button>
        </Section>
      </div>
    </>
  );
};
