import { gql } from '@entropyparadox/reusable-react';
import { Ad } from '../types/Ad';
import { AdTag } from '../types/AdTag';
import { Category } from '../types/Category';
import { Company } from '../types/Company';
import { Like } from '../types/Like';
import { Tag } from '../types/Tag';

export const ME = gql`
  query Me {
    me {
      id
      email
      name
      role
      phone
      organization
      categoryId
      createdAt
      updatedAt
      isActive
      category {
        id
        title
      }
    }
  }
`;

export const businessUserGetMyCompany = gql`
  query($userId: Int) {
    businessUserGetMyCompany(userId: $userId) {
      id
      createdAt
      updatedAt
      name
      address
      detailAddress
      homepage
    }
  }
`;

export interface CompanyData {
  businessUserGetMyCompany: Company;
}

export const updateUser = gql`
  mutation updateUser(
    $phone: String!
    $categoryId: Int!
    $organization: String
  ) {
    updateUser(
      phone: $phone
      categoryId: $categoryId
      organization: $organization
    ) {
      id
    }
  }
`;

export interface TAGSData {
  tags: Tag[];
}

export const TAGS = gql`
  query Tags {
    tags {
      id
      name
    }
  }
`;

export interface ADData {
  ad: Ad;
}

export const AD = gql`
  query($id: Int!) {
    ad(id: $id) {
      id
      title
      description
      thumbnail
      price
      adSize
      userId
      period
      address
      detailAddress
      createdAt
      possibledAt
      possibledEndAt
      detailContent
      lat
      lng
      codeDNG
      detailImage
      company {
        id
        name
        address
        detailAddress
        homepage
      }
      tags {
        id
        name
      }
      user {
        id
        phone
        securePhone
      }
    }
  }
`;

export interface ADSData {
  adsByBoundAndTag: Ad[];
}

export const ADS = gql`
  query {
    ads {
      id
      title
      description
      thumbnail
      price
      lat
      lng
    }
  }
`;

export interface FilterADSData {
  filterAds: AdTag[];
}

export const filterAds = gql`
  query($ids: [Int!]!) {
    filterAds(ids: $ids) {
      ads {
        id
        title
        description
        thumbnail
        price
        lat
        lng
        tags {
          id
          name
        }
      }
    }
  }
`;

export const adsByBoundsAndTags = gql`
  query($tagIds: [Int!]!, $bounds: String!) {
    adsByBoundAndTag(tagIds: $tagIds, bounds: $bounds) {
      id
      title
      description
      thumbnail
      price
      lat
      lng
      tags {
        id
        name
      }
    }
  }
`;

export const CreateAd = gql`
  mutation(
    $title: String!
    $description: String!
    $tagIds: [Int!]!
    $price: Int!
    $adSize: String!
    $period: String!
    $possibledAt: String!
    $possibledEndAt: String!
    $address: String!
    $detailAddress: String!
    $detailContent: String!
    $thumbnail: Upload!
    $detailImage: Upload!
  ) {
    createAd(
      title: $title
      description: $description
      tagIds: $tagIds
      price: $price
      adSize: $adSize
      period: $period
      possibledAt: $possibledAt
      possibledEndAt: $possibledEndAt
      address: $address
      detailAddress: $detailAddress
      detailContent: $detailContent
      thumbnail: $thumbnail
      detailImage: $detailImage
    ) {
      id
    }
  }
`;

export interface CategoriesData {
  categories: Category[];
}

export const CATEGORIES = gql`
  query {
    categories {
      id
      title
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany(
    $id: Int!
    $name: String!
    $address: String!
    $detailAddress: String!
    $homepage: String!
  ) {
    updateCompany(
      id: $id
      name: $name
      address: $address
      detailAddress: $detailAddress
      homepage: $homepage
    ) {
      id
    }
  }
`;

export const updateBusinessUser = gql`
  mutation updateBusinessUser($phone: String!) {
    updateBusinessUser(phone: $phone) {
      id
    }
  }
`;

export const companiesAds = gql`
  query($id: Int!) {
    companiesAds(id: $id) {
      id
      title
      description
      thumbnail
      price
      company {
        id
        name
        address
        detailAddress
        homepage
      }
      tags {
        id
        name
      }
    }
  }
`;

export interface CompaniesAdsData {
  companiesAds: Ad[];
}

export interface ADSMEData {
  adsMe: Ad[];
}

export const adsMe = gql`
  query {
    adsMe {
      id
      title
      description
      thumbnail
      price
    }
  }
`;

export interface ADSSearchData {
  searchPros: Ad[];
}

export const SearchQuery = gql`
  query($searchedValue: String!) {
    searchPros(value: $searchedValue) {
      id
      title
      description
      thumbnail
      price
      lat
      lng
      tags {
        id
        name
      }
    }
  }
`;

export const CreateContact = gql`
  mutation($description: String!, $price: Int!, $tagIds: [Int!]!) {
    createContact(description: $description, price: $price, tagIds: $tagIds) {
      id
    }
  }
`;

export const CreatePhone = gql`
  mutation($phone: Int!) {
    createPhone(phone: $phone) {
      id
    }
  }
`;

export const UpDatePhoneVerify = gql`
  mutation($id: Int!, $code: String!) {
    upDatePhoneVerify(id: $id, code: $code) {
      id
    }
  }
`;

export const findAd = gql`
  mutation($id: Int!) {
    findAd(id: $id) {
      id
    }
  }
`;

export const SignUpUser = gql`
  mutation(
    $email: String!
    $name: String!
    $password: String!
    $phone: String!
    $organization: String
    $agree: Boolean
    $categoryId: Int
    $kakaoId: Int
  ) {
    signupUser(
      email: $email
      name: $name
      password: $password
      phone: $phone
      organization: $organization
      agree: $agree
      categoryId: $categoryId
      kakaoId: $kakaoId
    ) {
      token
    }
  }
`;

export const SignUpBusiness = gql`
  mutation(
    $email: String!
    $name: String!
    $password: String!
    $phone: String!
    $companyName: String
    $address: String
    $detailAddress: String
    $homepage: String
    $license: Upload
    $kakaoId: Int
  ) {
    signupBusiness(
      email: $email
      name: $name
      password: $password
      phone: $phone
      companyName: $companyName
      address: $address
      detailAddress: $detailAddress
      homepage: $homepage
      license: $license
      kakaoId: $kakaoId
    ) {
      token
    }
  }
`;

export const UpDateAd = gql`
  mutation(
    $id: Int!
    $title: String
    $description: String
    $price: Int
    $tagIds: [Int!]!
    $adSize: String
    $period: String
    $possibledAt: String
    $possibledEndAt: String
    $address: String
    $detailAddress: String
    $detailContent: String
    $thumbnail: Upload
    $detailImage: Upload
  ) {
    updateAd(
      id: $id
      title: $title
      description: $description
      price: $price
      tagIds: $tagIds
      adSize: $adSize
      period: $period
      possibledAt: $possibledAt
      possibledEndAt: $possibledEndAt
      address: $address
      detailAddress: $detailAddress
      detailContent: $detailContent
      thumbnail: $thumbnail
      detailImage: $detailImage
    ) {
      id
    }
  }
`;

export const DeletedAd = gql`
  mutation($id: Int!) {
    deleteAd(id: $id) {
      id
    }
  }
`;

export const CreateLike = gql`
  mutation($adId: Int!) {
    createLike(adId: $adId) {
      id
    }
  }
`;

export interface LikeData {
  likeByAdIdUserId: Like;
}

export interface LikesData {
  likesByUserId: Like[];
}

export const LikesByUserId = gql`
  query($adId: Int!) {
    likeByAdIdUserId(adId: $adId) {
      id
      adId
    }
  }
`;

export const likesUser = gql`
  query {
    likesByUserId {
      id
      ad {
        id
        title
        description
        thumbnail
        price
        tags {
          id
          name
        }
      }
    }
  }
`;

export const DeleteLike = gql`
  mutation($adId: Int!) {
    deleteLike(adId: $adId) {
      id
    }
  }
`;

export const LOGIN = gql`
  mutation($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token
    }
  }
`;

export const createReport = gql`
  mutation($adId: Int!) {
    createReport(adId: $adId) {
      id
    }
  }
`;

export const changedPassword = gql`
  mutation(
    $oldPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    changedPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      id
    }
  }
`;

export const findPassword = gql`
  mutation($email: String!) {
    findPassword(email: $email) {
      id
    }
  }
`;

export const resetNewPassword = gql`
  mutation($token: String!, $newPassword: String!) {
    resetNewPassword(token: $token, newPassword: $newPassword) {
      id
    }
  }
`;

export interface StatisticsResponse {
  statistics: StatisticsData;
}

export interface StatisticsData {
  gu: string;
  dong: string;
  date: string;
  total: number;
  male: Statistics;
  female: Statistics;
}

export interface Statistics {
  F0T9: number;
  F10T14: number;
  F15T19: number;
  F20T24: number;
  F25T29: number;
  F30T34: number;
  F35T39: number;
  F40T44: number;
  F45T49: number;
  F50T54: number;
  F55T59: number;
  F60T64: number;
  F65T69: number;
  F70T74: number;
}

export const getStatistics = gql`
  query Statistics($codeDNG: Int!) {
    statistics(codeDNG: $codeDNG) {
      total
      date
      gu
      dong
      male {
        F0T9
        F10T14
        F15T19
        F20T24
        F25T29
        F30T34
        F35T39
        F40T44
        F45T49
        F50T54
        F55T59
        F60T64
        F65T69
        F70T74
      }
      female {
        F0T9
        F10T14
        F15T19
        F20T24
        F25T29
        F30T34
        F35T39
        F40T44
        F45T49
        F50T54
        F55T59
        F60T64
        F65T69
        F70T74
      }
    }
  }
`;
