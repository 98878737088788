import { Button, Section } from '@entropyparadox/reusable-react';
import React from 'react';

const CompanyCard = () => {
  return (
    <Section>
      <Button>카드 등록/관리</Button>
    </Section>
  );
};

export default CompanyCard;
