import {
  List,
  LoadingScreen,
  useQuery,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { adsMe, ADSMEData } from '../api';
import { ADItem } from '../components';
import { meState } from '../store';

const CompanyADlist = () => {
  const { loading, error, data } = useQuery<ADSMEData>(adsMe);
  const history = useHistory();
  const me = useRecoilValue(meState);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <List>
        {data.adsMe.map((ad) => (
          <ADItem ad={ad} />
        ))}
      </List>
      {data.adsMe.length === 0 ? (
        <div className="flex justify-center items-center text-gray-500 h-74vh">
          등록한 광고가 없습니다.
        </div>
      ) : (
        ''
      )}
      {console.log(data.adsMe)}
      <div className="h-16 w-full"></div>

      <button
        className="fixed bottom-0 w-full h-12
                  bg-brand-1 text-white disabled:bg-gray-300 sm:max-w-md"
        onClick={() =>
          me?.isActive
            ? history.push('/created/ad')
            : alert('관리자의 승인을 기다리는 중입니다.')
        }
      >
        새 광고 등록
      </button>
    </>
  );
};

export default CompanyADlist;
