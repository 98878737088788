import React, { useState } from 'react';
import { Ad } from '../types/Ad';
import { ReactComponent as FileUploadImg } from '../assets/images/file-upload.svg';
import { DaumPost } from './DaumPost';
import {
  Button,
  Chip,
  LoadingScreen,
  Section,
  TextArea,
  TextField,
  useIdParam,
  useMutation,
  useQuery,
  Blank,
} from '@entropyparadox/reusable-react';
import { TAGS, TAGSData, UpDateAd } from '../api';
import EXIF from 'exif-js';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import CKEditor from 'ckeditor4-react';
//@ts-ignore
import NumberInput from '@yaireo/react-number-input';

interface ADEditContentProps {
  ad: Ad;
}

export const ADEditContent: React.FC<ADEditContentProps> = ({ ad }) => {
  const id = useIdParam();
  const history = useHistory();
  const TagId = ad.tags.map((tag) => tag.id);
  const [tagIds, setTagIds] = useState<number[]>(TagId);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(ad.title);
  const [description, setDescription] = useState(ad.description);
  const [price, setPrice] = useState(Number(ad.price) || 0);
  const [adSize, setAdSize] = useState(ad.adSize);
  const [period, setPeriod] = useState(ad.period);
  const [possibledAt, setPossibledAt] = useState(ad.possibledAt);
  const [possibledEndAt, setPossibledEndAt] = useState(ad.possibledEndAt);
  const [address, setAddress] = useState(ad.address);
  const [detailAddress, setDetailAddress] = useState(ad.detailAddress);
  const [detailContent, setDetailContent] = useState(ad.detailContent);
  const [thumbnail, setThumbnail] = useState<File | null | undefined>();
  const [detailImage, setDetailImage] = useState<File | null | undefined>();
  const [load, setLoad] = useState(false);

  const handleData = (evt: any) => {
    setDetailContent(evt.editor.getData());
    evt.editor.on('fileUploadResponse', function (evt: any) {
      setTimeout(() => {
        setDetailContent(evt.editor.getData());
      }, 100);
    });
  };

  const { loading, error, data } = useQuery<TAGSData>(TAGS);

  const [updatedAd] = useMutation(UpDateAd, {
    variables: {
      id,
      title,
      description,
      tagIds,
      price: Number(price),
      adSize,
      period,
      possibledAt,
      possibledEndAt,
      address,
      detailAddress,
      detailContent,
      thumbnail,
      detailImage,
    },
    onCompleted: async () => {
      await setLoad(false);
      alert('수정이 완료 되었습니다.');
      history.push('/company');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  CKEditor.editorUrl = '/ckeditor/ckeditor.js';

  return (
    <>
      {load && <Blank />}
      <Section>
        <label htmlFor="fileupload">
          <div className="text-gray-800 text-lg mb-2 font-semibold">
            사진 업로드
          </div>
          <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
            <FileUploadImg />
            <div className="text-gray-500 text-xs w-3/5 text-center">
              Upload a file or drag and drop PNG, JPG, GIF up to 10MB
            </div>
          </div>
          {thumbnail ? (
            <img
              src={URL.createObjectURL(thumbnail)}
              alt=""
              className="w-full pt-10 flex flex-col justify-center items-center space-y-1"
            />
          ) : ad.thumbnail ? (
            <img
              className="w-full  pt-10  flex flex-col justify-center items-center space-y-1"
              src={ad.thumbnail}
              alt=""
            />
          ) : (
            ''
          )}
        </label>
        <input
          type="file"
          id="fileupload"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            const fileInfo = e.target.files?.item(0);
            console.log(fileInfo?.name);
            const reader = new FileReader();
            reader.onload = function () {
              if (fileInfo?.name) {
                EXIF.getData(fileInfo?.name, () => {
                  const orientation = EXIF.getTag(fileInfo, 'Orientation');
                  console.log(orientation);
                });
              }
            };
            e.target.validity.valid && setThumbnail(fileInfo);
          }}
        />

        <div>
          <div className="text-lg font-semibold pt-4 mb-3">광고 제목</div>
          <TextField
            placeholder="광고 제목을 입력해주세요."
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div>
          <div className="text-lg font-semibold pt-4 mb-3">간단 설명</div>
          <TextArea
            placeholder="광고에 대한 설명을 입력해주세요"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="text-lg font-semibold pt-4">광고 정보</div>
        <div>
          <label className="block mb-1 text-sm text-gray-800">금액</label>
          <div className="px-4 py-3 rounded-md border">
            <NumberInput
              placeholder="ex) 800,000"
              localeOptions={{
                currency: 'KRW',
                style: 'currency',
                currencyDisplay: 'symbol',
              }}
              defaultValue={Number(ad.price)}
              onChange={(v: any, e: any) => {
                setPrice(Number(v.value));
              }}
              className="w-full"
            />
          </div>
        </div>

        <div>
          <div className="text-sm mb-2 text-gray-800">광고 형태</div>
          <div className="flex flex-wrap">
            {data.tags.map((tag) => (
              <Chip
                key={tag.id}
                text={tag.name}
                style={{ margin: '0.25rem' }}
                onClick={() =>
                  tagIds.includes(tag.id)
                    ? setTagIds(tagIds.filter((id: number) => id !== tag.id))
                    : setTagIds(tagIds.concat(tag.id))
                }
                selected={tagIds.includes(tag.id)}
              />
            ))}
          </div>
        </div>

        <TextField
          label="크기"
          placeholder="크기를 입력해주세요."
          value={adSize}
          onChange={(e) => setAdSize(e.target.value)}
        />
        <TextField
          label="기간"
          placeholder="광고기간을 입력해주세요."
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        />

        <div>
          <label className="block mb-1 text-sm text-gray-800">
            광고 가능일
          </label>
          <div className="flex items-center">
            <input
              type="date"
              className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
              value={possibledAt}
              onChange={(e) => setPossibledAt(e.target.value)}
            />
            <span className="flex-shrink-0 mx-2">~</span>
            <input
              type="date"
              className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
              value={possibledEndAt}
              onChange={(e) => setPossibledEndAt(e.target.value)}
            />
          </div>
        </div>

        <div className="text-lg font-semibold pt-4">광고 지역</div>
        <div className="mt-4">
          <label
            htmlFor="expiration_date"
            className="block text-sm font-medium text-gray-700"
          >
            주소
          </label>
          <div className="grid grid-cols-4 gap-4 mt-1">
            <div className="col-span-3">
              <TextField
                type="text"
                name="address"
                id="address"
                placeholder="주소를 입력해주세요."
                value={address}
                disabled
              />
            </div>
            <button
              className="bg-brand-1 rounded-md text-white"
              onClick={() => setIsOpen(true)}
            >
              주소 검색
            </button>
          </div>
          {isOpen && (
            <DaumPost
              setAddress={(fullAddress) => setAddress(fullAddress)}
              setIsOpen={() => setIsOpen(false)}
            />
          )}
          <div className="mt-4">
            <TextField
              label="상세주소"
              placeholder="상세주소를 입력해주세요."
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
            />
          </div>
        </div>

        <label htmlFor="file-upload">
          <div className="text-gray-800 text-lg mb-2 font-semibold">
            상세 이미지 업로드
          </div>
          <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
            <FileUploadImg />
            <div className="text-gray-500 text-xs w-3/5 text-center">
              등록할 이미지를 첨부해 주세요.
            </div>
          </div>
          {detailImage ? (
            <img
              src={URL.createObjectURL(detailImage)}
              alt=""
              className="w-full pt-10  flex flex-col justify-center items-center space-y-1"
            />
          ) : ad.detailImage ? (
            <img
              className="w-full  pt-10  flex flex-col justify-center items-center space-y-1"
              src={ad.detailImage}
              alt=""
            />
          ) : (
            ''
          )}
        </label>
        <input
          type="file"
          id="file-upload"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            const fileInfo = e.target.files?.item(0);
            console.log(fileInfo?.name);
            const reader = new FileReader();
            reader.onload = function () {
              if (fileInfo?.name) {
                EXIF.getData(fileInfo?.name, () => {
                  const orientation = EXIF.getTag(fileInfo, 'Orientation');
                  console.log(orientation);
                });
              }
            };
            e.target.validity.valid && setDetailImage(fileInfo);
          }}
        />

        <div className="text-lg font-semibold pt-4">상세 내용</div>
        <CKEditor
          data={detailContent}
          type="classic"
          name="ckeditor"
          onChange={handleData}
          config={{
            extraPlugins: ['uploadimage'],
            uploadUrl: `${process.env.REACT_APP_API_URL}/images/upload`,
          }}
        />

        <div className="w-full  flex items-center space-x-4 min-w-max">
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              setLoad(true);
              updatedAd();
            }}
          >
            등록하기
          </Button>
        </div>
      </Section>
    </>
  );
};
