import React, { useState } from 'react';
import {
  TopNavbar,
  HamburgerButton,
  Tabs,
  Tab,
  useRecoilValue,
  LoadingScreen,
  useQuery,
} from '@entropyparadox/reusable-react';
import { HambergerMenu, LinkadTopNavBar } from '../components';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import CompanyADlist from './CompanyADlist';
import CompanyCard from './CompanyCard';
import CompanyPaymentPage from './CompanyPaymentPage';
import CompanyEditPage from './CompanyEditPage';
import { meState } from '../store';
import { businessUserGetMyCompany, CompanyData } from '../api';
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';

export const CompanyMyPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const me = useRecoilValue(meState);

  const { loading, error, data } = useQuery<CompanyData>(
    businessUserGetMyCompany,
    {
      variables: { userId: me?.id },
    },
  );

  if (!me) return <LoadingScreen />;
  if (loading) return <LoadingScreen />;
  if (error) return <></>;

  return (
    <>
      <div className="sm:hidden">
        <TopNavbar
          title="마이페이지"
          right={<HamburgerButton onClick={() => setOpen(true)} />}
          left={<LogoIcon className="h-8 w-28" />}
        />
      </div>
      <HambergerMenu isOpen={open} setClose={() => setOpen(false)} />
      <div className="hidden sm:block inset-0">
        <LinkadTopNavBar />
        <div className=" text-2xl mt-24 text-center">마이페이지</div>
      </div>

      <div className="sm:max-w-md sm:mx-auto sm:mt-8">
        <Tabs padding="px-3">
          <Tab
            text="광고관리"
            active={pathname === '/company'}
            onClick={() => {
              history.push('/company');
            }}
          />

          {/* <Tab
          text="카드관리"
          active={pathname === '/company/card'}
          onClick={() => {
            history.push('/company/card');
          }}
        />

        <Tab
          text="결제내역"
          active={pathname === '/company/payment'}
          onClick={() => {
            history.push('/company/payment');
          }}
        /> */}

          <Tab
            text="프로필"
            active={pathname === '/company/profile'}
            onClick={() => {
              history.push('/company/profile');
            }}
          />
        </Tabs>
        <Switch>
          <Route
            exact
            path="/company/profile"
            render={() => (
              <CompanyEditPage
                me={me}
                company={data?.businessUserGetMyCompany}
              />
            )}
          />
          <Route exact path="/company/payment" component={CompanyPaymentPage} />
          <Route exact path="/company/card" component={CompanyCard} />
          <Route exact path="/company" component={CompanyADlist} />
        </Switch>
      </div>
    </>
  );
};
