import {
  BackButton,
  Button,
  Chip,
  LoadingScreen,
  Section,
  TextArea,
  TextField,
  TopNavbar,
  useMutation,
  useQuery,
  Blank,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { ReactComponent as FileUploadImg } from '../assets/images/file-upload.svg';
import { DaumPost } from '../components/DaumPost';
import { LinkadTopNavBar } from '../components/LinkadTopNavBar';
import { CreateAd, TAGS, TAGSData } from '../api';
import { useHistory } from 'react-router-dom';
import EXIF from 'exif-js';
//@ts-ignore
import CKEditor from 'ckeditor4-react';
//@ts-ignore
import NumberInput from '@yaireo/react-number-input';

const ADAddPage = () => {
  const history = useHistory();

  const [tagIds, setTagIds] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [adSize, setAdSize] = useState('');
  const [period, setPeriod] = useState('');
  const [possibledAt, setPossibledAt] = useState('');
  const [possibledEndAt, setPossibledEndAt] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [detailContent, setDetailContent] = useState('');
  const [thumbnail, setThumbnail] = useState<File | null | undefined>();
  const [detailImage, setDetailImage] = useState<File | null | undefined>();
  const [load, setLoad] = useState(false);

  const handleData = (evt: any) => {
    setDetailContent(evt.editor.getData());
    evt.editor.on('fileUploadResponse', function (evt: any) {
      setTimeout(() => {
        setDetailContent(evt.editor.getData());
      }, 100);
    });
  };

  CKEditor.editorUrl = '/ckeditor/ckeditor.js';

  const { loading, error, data } = useQuery<TAGSData>(TAGS);

  const [createdAd] = useMutation(CreateAd, {
    variables: {
      title: title,
      description: description,
      tagIds: tagIds,
      price: Number(price),
      adSize: adSize,
      period: period,
      possibledAt: possibledAt,
      possibledEndAt: possibledEndAt,
      address: address,
      detailAddress: detailAddress,
      detailContent: detailContent,
      thumbnail: thumbnail,
      detailImage: detailImage,
    },
    onCompleted: async () => {
      await setLoad(false);
      alert('등록이 완료 되었습니다.');
      history.push('/company');
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <LinkadTopNavBar />
      <div className="max-w-md mx-auto">
        {load && <Blank />}
        <div className="sm:hidden">
          <TopNavbar title="광고 등록하기" left={<BackButton />} />
        </div>

        <div className="hidden sm:block inset-0">
          <div className=" text-2xl mt-24 text-center">광고 등록하기</div>
        </div>

        <Section>
          <label htmlFor="upload">
            <div className="text-gray-800 text-lg font-semibold mb-2">
              광고 썸네일 업로드
            </div>
            {thumbnail && (
              <img
                src={URL.createObjectURL(thumbnail)}
                alt=""
                className="w-full py-10 flex flex-col justify-center items-center space-y-1"
              />
            )}
            {!thumbnail && (
              <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
                <FileUploadImg />
                <div className="text-brand-1">
                  등록할 이미지를 첨부해 주세요.
                </div>
              </div>
            )}
          </label>
          <input
            type="file"
            id="upload"
            className="hidden"
            accept="image/*"
            onChange={(e) => {
              const fileInfo = e.target.files?.item(0);
              console.log(fileInfo?.name);
              const reader = new FileReader();
              reader.onload = function () {
                if (fileInfo?.name) {
                  EXIF.getData(fileInfo?.name, () => {
                    const orientation = EXIF.getTag(fileInfo, 'Orientation');
                    console.log(orientation);
                  });
                }
              };
              e.target.validity.valid && setThumbnail(fileInfo);
            }}
          />

          <div>
            <div className="text-lg font-semibold pt-4 mb-3">광고 제목</div>
            <TextField
              placeholder="광고 제목을 입력해주세요."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div>
            <div className="text-lg font-semibold pt-4 mb-3">간단 설명</div>
            <TextArea
              placeholder="광고에 대한 설명을 입력해주세요"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="text-lg font-semibold pt-4">광고 정보</div>
          <div>
            <label className="block mb-1 text-sm text-gray-800">금액</label>
            <div className="px-4 py-3 rounded-md border">
              <NumberInput
                placeholder="ex) 800,000"
                localeOptions={{
                  currency: 'KRW',
                  style: 'currency',
                  currencyDisplay: 'symbol',
                }}
                onChange={(v: any, e: any) => setPrice(v.value)}
                className="w-full"
              />
            </div>
          </div>

          <div>
            <div className="text-sm mb-2 text-gray-800">광고 형태</div>
            <div className="flex flex-wrap">
              {data.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  text={tag.name}
                  style={{ margin: '0.25rem' }}
                  onClick={() =>
                    tagIds.includes(tag.id)
                      ? setTagIds(tagIds.filter((id: number) => id !== tag.id))
                      : setTagIds(tagIds.concat(tag.id))
                  }
                  selected={tagIds.includes(tag.id)}
                />
              ))}
            </div>
          </div>

          <TextField
            label="크기"
            placeholder="ex)4010*2260"
            value={adSize}
            onChange={(e) => setAdSize(e.target.value)}
          />
          <TextField
            label="기간"
            placeholder="ex)2주,5일"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          />
          <div>
            <label className="block mb-1 text-sm text-gray-800">
              광고 가능일
            </label>
            <div className="flex items-center">
              <input
                type="date"
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                value={possibledAt}
                onChange={(e) => setPossibledAt(e.target.value)}
              />
              <span className="flex-shrink-0 mx-2">~</span>
              <input
                type="date"
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                value={possibledEndAt}
                onChange={(e) => setPossibledEndAt(e.target.value)}
              />
            </div>
          </div>

          <div className="text-lg font-semibold pt-4">광고 지역</div>
          <div className="mt-4">
            <label
              htmlFor="expiration_date"
              className="block text-sm font-medium text-gray-700"
            >
              주소
            </label>
            <div className="grid grid-cols-4 gap-4 mt-1">
              <div className="col-span-3">
                <TextField
                  type="text"
                  name="address"
                  id="address"
                  placeholder="주소를 입력해주세요."
                  value={address}
                  disabled
                />
              </div>
              <button
                className="bg-brand-1 rounded-md text-white"
                onClick={() => setIsOpen(true)}
              >
                주소 검색
              </button>
            </div>
            {isOpen && (
              <DaumPost
                setAddress={(fullAddress) => setAddress(fullAddress)}
                setIsOpen={() => setIsOpen(false)}
              />
            )}
            <div className="mt-4">
              <TextField
                label="상세주소"
                placeholder="상세주소를 입력해주세요."
                value={detailAddress}
                onChange={(e) => setDetailAddress(e.target.value)}
              />
            </div>
          </div>

          <label htmlFor="file-upload">
            <div className="text-lg font-semibold pt-4 mb-2">
              상세 이미지 업로드
            </div>
            {detailImage && (
              <img
                src={URL.createObjectURL(detailImage)}
                alt=""
                className=" w-full py-10 flex flex-col justify-center items-center space-y-1"
              />
            )}
            {!detailImage && (
              <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
                <FileUploadImg />
                <div className="text-brand-1">
                  등록할 이미지를 첨부해 주세요.
                </div>
              </div>
            )}
          </label>
          <input
            type="file"
            id="file-upload"
            className="hidden"
            accept="image/*"
            onChange={(e) => {
              const fileInfo = e.target.files?.item(0);
              console.log(fileInfo?.name);
              const reader = new FileReader();
              reader.onload = function () {
                if (fileInfo?.name) {
                  EXIF.getData(fileInfo?.name, () => {
                    const orientation = EXIF.getTag(fileInfo, 'Orientation');
                    console.log(orientation);
                  });
                }
              };
              e.target.validity.valid && setDetailImage(fileInfo);
            }}
          />

          <div className="text-lg font-semibold pt-4">상세 내용</div>
          <CKEditor
            data={detailContent}
            type="classic"
            onChange={handleData}
            config={{
              extraPlugins: ['uploadimage'],
              uploadUrl: `${process.env.REACT_APP_API_URL}/images/upload`,
            }}
          />

          <div className="w-full  flex items-center space-x-4 min-w-max">
            <Button
              disabled={
                !title ||
                !description ||
                tagIds.length === 0 ||
                !Number(price) ||
                !adSize ||
                !period ||
                !possibledAt ||
                !possibledEndAt ||
                !address ||
                !detailAddress ||
                !detailImage
              }
              style={{ width: '100%' }}
              onClick={() => {
                setLoad(true);
                createdAd();
              }}
            >
              등록하기
            </Button>
          </div>
        </Section>
      </div>
    </>
  );
};

export default ADAddPage;
