import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Marker, MenuFilterIcon } from '../components';
import GoogleMapReact, { Coords, Maps, MapOptions } from 'google-map-react';
import { ReactComponent as SearchCloseIcon } from '../assets/images/icon-search-close.svg';
import { Link, useHistory } from 'react-router-dom';
import { Bounds, Mapdata } from '../types/Mapdata';
import { Ad } from '../types/Ad';
import { AdTag } from '../types/AdTag';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';

type MapProps = {
  mapdata?: Mapdata;
  // setMap: Function;
  callback: Function;
  places?: Ad[];
  height: string;
};

export const GoogleMap: React.FC<MapProps> = ({
  mapdata,
  places,
  callback,
  height,
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [bound, setBound] = useState({});
  const [willUpdate, setWillUpdate] = useState(false);
  const history = useHistory();

  function createMapOptions(maps: Maps): MapOptions {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      // zoomControlOptions: {
      // position: maps.ControlPosition.RIGHT_CENTER,
      // style: maps.ZoomControlStyle.SMALL,
      // },
      // mapTypeControlOptions: {
      // position: maps.ControlPosition.TOP_RIGHT,
      // },
      mapTypeControl: true,
      fullscreenControl: false,
    };
  }

  const boundsChanged = (e: any) => {
    console.log('bound: ', e);
    setBound(e);
    localStorage.setItem('mapdata', JSON.stringify(e));
    if (willUpdate) {
      setWillUpdate(false);
      callback(e);
    }
  };

  const searchHere = () => {
    callback(bound);
    history.push('/');
  };

  const initAutocomplete = (map: any, maps: any) => {
    const google = window.google;
    if (!input.current) {
      console.log('search input is not connected');
      return;
    }
    if (!google) {
      console.log('google is not loaded');
      return;
    }
    // Create the search box and link it to the UI element.
    const searchBox = new google.maps.places.SearchBox(input.current);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    let markers: any = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places?.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker: any) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place: any) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }
        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          }),
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
      setWillUpdate(true);
    });
  };

  return (
    <div style={{ height: height, width: '100%' }}>
      <div className="px-6 py-4 flex items-center space-x-4 bg-white">
        <div className="w-full">
          <form
            className="flex items-center px-4 w-full h-10
                 rounded-full border border-gray-200 focus-within:border-brand-1"
            onSubmit={(e) => {
              e.preventDefault();
              // onSearch();
            }}
          >
            <SearchIcon />
            <input
              style={{ border: 'none' }}
              ref={input}
              type="text"
              placeholder="장소를 검색해주세요."
              className="flex-1 text-sm outline-none focus:outline-none focus:ring-0 placeholder-gray-400"
            />
          </form>
        </div>
        {/* <Link to="/filter">
          <MenuFilterIcon />
        </Link> */}
      </div>
      <div className="flex justify-center w-full">
        <div
          className="absolute top-20 z-10 rounded-3xl bg-white py-2 px-6 cursor-pointer text-brand-1 font-bold"
          onClick={searchHere}
        >
          <span>현 위치 검색</span>
        </div>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GMAP_API_KEY ?? '',
          libraries: ['places'],
        }}
        defaultCenter={{ lat: 37.513497, lng: 127.031924 }}
        defaultZoom={13}
        center={mapdata?.center}
        zoom={mapdata?.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => initAutocomplete(map, maps)}
        onChange={(e) => boundsChanged(e)}
        options={createMapOptions}
      >
        {places?.map((place) => {
          return (
            <Marker
              key={place.id}
              id={place.id}
              text={place.title}
              lat={place.lat}
              lng={place.lng}
              onMarkerClick={(e: any) => {
                callback(bound);
                // history.push(`/ads/${place.id}`);
              }}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};
