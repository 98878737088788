import { Button, useMutation } from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { createReport } from '../api';

interface ModalPopUpProps {
  setClose: () => void;
  title?: string;
  text1?: string;
  text2?: string;
  link1?: string;
  link2?: string;
  alert1?: string;
  ad: number;
}

const ModalPopUp: FC<ModalPopUpProps> = ({
  setClose,
  title,
  text1,
  text2,
  link1,
  link2,
  alert1,
  ad,
}) => {
  const [CreateReport] = useMutation(createReport, {
    variables: {
      adId: Number(ad),
    },
    onCompleted: () => {
      setClose();
      alert(alert1);
    },
  });

  return (
    <div className="w-80 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/3  inset-x-0 p-2 transition transform origin-top-right">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center float-right mb-4">
            <div className="-mr-2">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setClose()}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-14 text-center w-full">
            <div className="font-bold text-base mb-9">{title}</div>
            <div className="w-full my-4">
              <Button
                text={text1}
                style={{ width: '100%' }}
                to={link1}
                onClick={() => CreateReport()}
              />
            </div>
            {text2 ? (
              <div className="w-full my-4 text-brand-1 ">
                <Button
                  theme="brand-2"
                  text={text2}
                  onClick={() => CreateReport()}
                  style={{ width: '100%', color: '#7950F2' }}
                  to={link2}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopUp;
