import React from 'react';
import { ListItem } from '@entropyparadox/reusable-react';
import { AspectRatioImage } from '@entropyparadox/reusable-react';
import { Link, useHistory } from 'react-router-dom';
import { Ad } from '../types/Ad';
import AddLikeIcon from './AddLikeIcon';
import { ReactComponent as HeartIcon } from '../assets/images/heart-icon.svg';
import { User } from '../types/User';

interface FilterAdsListProps {
  ad: Ad;
  me?: User;
}

export const FilterAdsList: React.FC<FilterAdsListProps> = ({ ad, me }) => {
  const history = useHistory();

  const handleSignUp = () => {
    alert('로그인이 필요합니다.');
    history.push('/login');
  };

  return (
    <ListItem>
      <div className="flex space-x-3 z-0 text-left">
        <div className="w-1/3">
          <Link to={`/ads/${ad.id}`}>
            <AspectRatioImage
              src={ad.thumbnail || ''}
              alt="pro"
              pb="pb-13/10"
              rounded="rounded"
            />
          </Link>
        </div>
        <div className="w-2/3 relative min-h-max">
          <Link to={`/ads/${ad.id}`}>
            <div className="flex flex-wrap">
              {ad.tags.map((tag) => (
                <div
                  key={tag.id}
                  className="text-sm text-gray-600 font-bold mr-2"
                >
                  {tag.name}
                </div>
              ))}
            </div>
            <div className="text-lg truncate-2-lines text-gray-900 font-bold">
              {ad.title}
            </div>

            <div className="text-base text-gray-600 truncate-2-lines overflow-hidden">
              {ad.description}
            </div>
            <div className="w-full h-6 truncate text-gray-500 text-sm mt-1">
              {String(ad.price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
            </div>
          </Link>
          <div className="absolute bottom-0 right-0 text-right font-bold text-gray-700 w-max">
            <div className="w-6 h-6 flex items-center justify-center bg-white">
              {me?.role === 'BUSINESS' ? (
                ''
              ) : (
                <>
                  {!me ? (
                    <HeartIcon onClick={handleSignUp} />
                  ) : (
                    <AddLikeIcon ad={ad} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};
