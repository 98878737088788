import React, { useState } from 'react';
import {
  TopNavbar,
  Section,
  BackButton,
  TextField,
  BottomFixedButton,
  TextArea,
  FlexWrap,
  Chip,
  LoadingScreen,
  useQuery,
  useMutation,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import { CreateContact, TAGS, TAGSData } from '../api';
import { useHistory } from 'react-router-dom';
import { LinkadTopNavBar } from '../components';
import { meState } from '../store';
//@ts-ignore
import NumberInput from '@yaireo/react-number-input';

export const ADRequest: React.FC = () => {
  const me = useRecoilValue(meState);
  const history = useHistory();

  const [tagIds, setTagIds] = useState<number[]>([]);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);

  const { loading, error, data } = useQuery<TAGSData>(TAGS);

  const [createContact] = useMutation(CreateContact, {
    variables: { description, price: Number(price), tagIds },
    onCompleted: () => {
      alert('신청이 완료되었습니다.');
      history.replace(`/`);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  if (!me) {
    history.push('/login');
  }

  return (
    <>
      <LinkadTopNavBar />

      <div className="sm:hidden">
        <TopNavbar title="매물 요청하기" left={<BackButton />} />
      </div>
      <div className="hidden sm:block inset-0">
        <div className="text-2xl mt-15 md:mt-24 text-center">매물 요청하기</div>
      </div>

      <div className="max-w-md mx-auto">
        <Section>
          <TextArea
            label="간단 설명"
            placeholder="진행하려는 광고에 대해 설명해주세요."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* <TextField
            type="number"
            label="광고 금액"
            placeholder="금액을 입력해주세요"
            value={price.toString()}
            onChange={(e) => setPrice(Number(e.target.value))}
          /> */}

          <div>
            <label className="block mb-1 text-sm text-gray-800">금액</label>
            <div className="px-4 py-3 rounded-md border">
              <NumberInput
                placeholder="ex) 800,000"
                localeOptions={{
                  currency: 'KRW',
                  style: 'currency',
                  currencyDisplay: 'symbol',
                }}
                onChange={(e: any) => setPrice(Number(e.value))}
                className="w-full"
              />
            </div>
          </div>

          <label className="block mb-1 text-sm text-gray-800">광고 형태</label>
          <FlexWrap>
            {data.tags.map((tag) => (
              <Chip
                key={tag.id}
                text={tag.name}
                onClick={() =>
                  tagIds.includes(tag.id)
                    ? setTagIds(tagIds.filter((id: number) => id !== tag.id))
                    : setTagIds(tagIds.concat(tag.id))
                }
                selected={tagIds.includes(tag.id)}
              />
            ))}
            {/* {console.log(Chip.arguments)} */}
          </FlexWrap>
        </Section>
        <BottomFixedButton
          text="신청하기"
          onClick={() => createContact()}
          style={{ maxWidth: '448px' }}
          disabled={!description || price === 0}
        />
      </div>
    </>
  );
};

export default ADRequest;
