import React, { useState } from 'react';
import {
  TextField,
  Button,
  PhoneNumberField,
  Select,
  useMutation,
  Section,
  Checkbox,
  Label,
} from '@entropyparadox/reusable-react';
import { Category } from '../types/Category';
import { ReactComponent as FileUploadImg } from '../assets/images/file-upload.svg';
import { DaumPost } from './DaumPost';
import { CreatePhone, UpDatePhoneVerify } from '../api/queries';
import { Role } from '../types/User';
interface SignUpFormProps {
  name: string;
  setName: (e: any) => void;
  phone: string;
  setPhone: (e: any) => void;
  authenticationNumber: boolean;
  setAuthenticationComplet: (e: any) => void;
  authenticationComplet: boolean;
  setAuthenticationNumber: (e: any) => void;
  role: Role;
  organization: string;
  setorganization: (e: any) => void;
  category: Category[];
  setCategory: (e: any) => void;
  email: string;
  setEmail: (e: any) => void;
  password: string;
  setPassword: (e: any) => void;
  passwordAgain: string;
  setPasswordAgain: (e: any) => void;
  companyName: string;
  setCompanyName: (e: any) => void;
  address: string;
  setAddress: (e: any) => void;
  detailAddress: string;
  setDetailAddress: (e: any) => void;
  homepage: string;
  setHomepage: (e: any) => void;
  confirm: boolean;
  setConfirm: (e: any) => void;
  license: File | null | undefined;
  setLicense: (e: any) => void;
  agree: boolean;
  setAgree: (e: any) => void;
  place: boolean;
  setPlace: (e: any) => void;
}

const SingUpForm: React.FC<SignUpFormProps> = ({
  name,
  setName,
  phone,
  setPhone,
  authenticationNumber,
  setAuthenticationComplet,
  authenticationComplet,
  setAuthenticationNumber,
  role,
  organization,
  setorganization,
  category,
  setCategory,
  email,
  setEmail,
  password,
  setPassword,
  passwordAgain,
  setPasswordAgain,
  companyName,
  setCompanyName,
  address,
  setAddress,
  detailAddress,
  setDetailAddress,
  homepage,
  setHomepage,
  confirm,
  setConfirm,
  agree,
  setAgree,
  license,
  setLicense,
  setPlace,
  place,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState('');
  const [phoneId, setPhoneId] = useState('');
  const [marketing, setMarketing] = useState(false);

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setPhoneId(createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  return (
    <>
      <TextField
        label="*이름"
        placeholder="이름을 입력해주세요"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <PhoneNumberField
        label="*핸드폰 번호"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        disabled={authenticationComplet}
      />
      {authenticationNumber ? (
        <>
          {!authenticationComplet ? (
            <>
              <Button
                variant="outlined"
                onClick={() =>
                  phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
                }
              >
                인증번호 재전송
              </Button>
              <div className="mt-4">
                <div className="grid grid-cols-4 gap-4 mt-1">
                  <input
                    type="text"
                    name=""
                    className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                    placeholder="인증번호를 입력해주세요."
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    className="col-span-1 bg-brand-1 rounded-md text-white"
                    onClick={() =>
                      code
                        ? upDatePhoneVerify()
                        : alert('인증번호를 입력해주세요.')
                    }
                  >
                    확인
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>{authenticationComplet && <div>* 인증이 완료되었습니다.</div>}</>
          )}
        </>
      ) : (
        <Button
          variant="outlined"
          onClick={() =>
            phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
          }
        >
          인증번호 보내기
        </Button>
      )}

      {role === Role.USER && (
        <>
          <TextField
            label="*상호명"
            placeholder="상호명을 입력해주세요."
            value={organization}
            onChange={(e) => setorganization(e.target.value)}
          />
          <Select
            label="*업종"
            placeholder="업종을 선택해주세요"
            onChange={(e) => setCategory(e.target.value)}
          >
            {category.map((category) => (
              <option value={category.id}>{category.title}</option>
            ))}
          </Select>
        </>
      )}

      <TextField
        label="*이메일"
        placeholder="이메일을 입력해주세요"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="*비밀번호"
        placeholder="비밀번호를 입력해주세요"
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        label="*비밀번호 확인"
        placeholder="비밀번호를 재입력해주세요"
        value={passwordAgain}
        type="password"
        onChange={(e) => setPasswordAgain(e.target.value)}
      />

      {role === Role.BUSINESS && (
        <>
          <TextField
            label="*회사명"
            placeholder="회사명을 입력해주세요."
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <div className="mt-4">
            <label
              htmlFor="expiration_date"
              className="block text-sm font-medium text-gray-700"
            >
              *회사주소
            </label>
            <div className="grid grid-cols-4 gap-4 mt-1">
              <div className="col-span-3">
                <TextField
                  type="text"
                  name="address"
                  id="phone_number_f"
                  placeholder="주소를 입력해주세요."
                  value={address}
                  disabled
                />
              </div>
              <button
                className="col-span-1 bg-brand-1 rounded-md text-white"
                onClick={() => setIsOpen(true)}
              >
                주소 검색
              </button>
            </div>
            {isOpen && (
              <DaumPost
                setAddress={(fullAddress) => setAddress(fullAddress)}
                setIsOpen={() => setIsOpen(false)}
              />
            )}
          </div>
          <TextField
            label="*상세주소"
            placeholder="상세주소를 입력해주세요."
            value={detailAddress}
            onChange={(e) => setDetailAddress(e.target.value)}
          />
          <TextField
            label="홈페이지"
            placeholder="홈페이지 주소를 입력해주세요."
            value={homepage}
            onChange={(e) => setHomepage(e.target.value)}
          />

          <label htmlFor="fileupload">
            <div className="text-gray-800 text-sm mb-2">
              *사업자 등록증 첨부하기 (이미지 또는 PDF 파일 가능)
            </div>
            <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
              <FileUploadImg />
              <div className="text-brand-1">사업자 등록증 첨부하기</div>
            </div>
            {license && (
              <>
                <p>{license.name}</p>
                <img
                  className="w-full py-10  flex flex-col justify-center items-center space-y-1"
                  src={URL.createObjectURL(license)}
                  alt=""
                />
              </>
            )}
          </label>
          <input
            type="file"
            id="fileupload"
            className="hidden"
            accept="license/*"
            onChange={(e) => {
              e.target.validity.valid && setLicense(e.target.files?.item(0));
            }}
          />
        </>
      )}

      <div className="space-y-2">
        <div className="">
          <Checkbox
            label="전체 동의"
            onChange={() => {
              const allChecked = confirm && place && agree;
              setConfirm(!allChecked);
              setPlace(!allChecked);
              setAgree(!agree);
            }}
            checked={confirm && place && agree}
          />
        </div>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="confirm"
            onChange={() => setConfirm(!confirm)}
            checked={confirm}
          />

          <Label htmlFor="confirm" margin="m-0">
            <span className="text-base">
              회원가입 시
              <a
                href="https://www.notion.so/e06f92768efe47f7a7dae50086ddd3d0
                "
                target="blank"
                className="font-semibold"
              >
                개인정보 처리방침
              </a>
              과{' '}
              <a
                href="https://www.notion.so/fa69d4d7363a4717ad2a3eb71bc46b37"
                target="blank"
                className="font-semibold"
              >
                이용약관
              </a>
              을 확인하였으며, 이에 동의합니다. (필수)
            </span>
          </Label>
        </div>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="place"
            onChange={() => setPlace(!place)}
            checked={place}
          />
          <a
            href="https://www.notion.so/18b444ffe3bf4211914e6a9ff7ecc638"
            target="blank"
          >
            <Label htmlFor="place" margin="m-0">
              <span className="text-base">
                <span className="font-semibold cursor-pointer">
                  위치기반 서비스 이용약관
                </span>{' '}
                동의 (필수)
              </span>
            </Label>
          </a>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="agree"
            onChange={() => setAgree(!agree)}
            checked={agree}
          />
          <a
            href="https://www.notion.so/fed80a03b9b34392a530fc4a1aa89513"
            target="blank"
          >
            <Label htmlFor="agree" margin="m-0">
              <span className="text-base ">
                <span className="font-semibold cursor-pointer">
                  마케팅 수신
                </span>{' '}
                동의 (선택)
              </span>
            </Label>
          </a>
        </div>
      </div>

      {/* {role === Role.USER && (
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="confirm"
                type="checkbox"
                defaultChecked={agree}
                onChange={() => setAgree(!agree)}
                className="focus:ring-0 h-4 w-4 text-brand-1 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="confirm" className="text-gray-600">
                마케팅 수신 동의 (선택)
              </label>
            </div>
          </div>
        )} */}
      {/* </div> */}
    </>
  );
};

export default SingUpForm;
